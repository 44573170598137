import Button from "@ingka/button"
import { InsightsUtilsRunners } from "features/insights/utils/llmUtils/llmUtilRunners"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"

const NewChatButton = () => {
  const { runChatCreateWithPrompt } = InsightsUtilsRunners()
  const { inputDisabled } = useInsightsHelperFunctions()

  return (
    <Button
      type="primary"
      small={true}
      disabled={inputDisabled}
      onClick={() => runChatCreateWithPrompt()}
    >
      New chat
    </Button>
  )
}

export default NewChatButton
