import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { PromptType, SavedPrompt } from "../../types"
import PromptCrud from "../../PromptCrud"
import InsightsButton from "../../components/buttons/InsightsButton"
import SummaryButton from "../../components/buttons/SummaryButton"
import {
  addSavedPrompt,
  deleteSavedPrompt,
  saveCurrentPrompt,
} from "../../insightsSlice"

export function usePromptCruds() {
  const dispatch = useAppDispatch()
  const { savedInsightsPrompts, savedSummaryPrompts } = useAppSelector(
    (state: RootState) => state.insights,
  )
  const { user } = useAppSelector((state: RootState) => state.auth)

  const onPromptDelete = (promptId: string, promptType: PromptType) => {
    if (!user) return
    dispatch(
      deleteSavedPrompt({
        collectionIdentifier: user.id,
        promptType,
        promptId,
      }),
    )
  }

  const onPromptSave = (
    selectedPrompt: SavedPrompt,
    promptType: PromptType,
  ) => {
    if (!user) return
    dispatch(
      saveCurrentPrompt({
        collectionIdentifier: user.id,
        prompt: selectedPrompt,
        promptType,
      }),
    )
  }

  const onAddPrompt = (prompt: SavedPrompt, promptType: PromptType) => {
    if (!user) return
    dispatch(
      addSavedPrompt({
        prompt,
        collectionIdentifier: user.id,
        promptType,
      }),
    )
  }

  const prePromptCrud = (
    <PromptCrud
      prompts={savedInsightsPrompts}
      promptType="insight"
      promptAction={<InsightsButton />}
      onAddPrompt={onAddPrompt}
      onPromptSave={onPromptSave}
      onPromptDelete={onPromptDelete}
    />
  )

  const summaryPromptCrud = (
    <PromptCrud
      prompts={savedSummaryPrompts}
      promptType="chat"
      promptAction={<SummaryButton />}
      onAddPrompt={onAddPrompt}
      onPromptSave={onPromptSave}
      onPromptDelete={onPromptDelete}
    />
  )

  return {
    prePromptCrud,
    summaryPromptCrud,
    savedInsightsPrompts,
    savedSummaryPrompts,
  }
}
