import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { LLMModel } from "features/insights/types"

const useInsightsHelperFunctions = () => {
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { conversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  const currentThread = Array.isArray(insightsState.chatThreads)
    ? insightsState.chatThreads.find(
        (thread) => thread.uuid === insightsState.selectedThread,
      )
    : undefined

  const currentThreadIndex = Array.isArray(insightsState.chatThreads)
    ? insightsState.chatThreads.findIndex(
        (thread) => thread.uuid === insightsState.selectedThread,
      )
    : -1

  const previewedThread = Array.isArray(insightsState.chatThreads)
    ? insightsState.chatThreads.find(
        (thread) => thread.uuid === insightsState.previewedThread,
      ) || null
    : null

  const selectedModel = insightsState.insightsModelConfig.find(
    (model: { name: LLMModel }) =>
      model.name === insightsState.selectedChatModel,
  )

  const largeContextEnabled =
    insightsState.largeContextWindowEnabled && selectedModel?.largeContextWindow

  const contextWindow = largeContextEnabled
    ? selectedModel?.largeContextWindow
    : selectedModel?.contextWindow

  const inputDisabled =
    !Boolean(conversations) ||
    insightsState.insightsLoading ||
    insightsState.insightsSummaryLoading

  return {
    currentThread,
    currentThreadIndex,
    previewedThread,
    selectedModel,
    largeContextEnabled,
    contextWindow,
    inputDisabled,
  }
}

export default useInsightsHelperFunctions
