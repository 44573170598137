interface ChatStyles {
  llmChatPadding: (clientHeight: number | null) => React.CSSProperties
  noMessagesHeight: (clientHeight: number | null) => React.CSSProperties
  sampleText: (fadeState: boolean) => React.CSSProperties
  exportButton: React.CSSProperties
}

export const styles: ChatStyles = {
  llmChatPadding: (clientHeight) => ({
    paddingBottom: clientHeight ? `calc(${clientHeight}px + 7rem)` : "0px",
  }),

  noMessagesHeight: (clientHeight) => ({
    minHeight: "24rem",
    height: clientHeight ? `calc(100vh - (${clientHeight}px + 13rem))` : "0px",
  }),

  sampleText: (fadeState) => ({
    opacity: fadeState ? 1 : 0,
    fontSize: "1.45rem",
  }),

  exportButton: {
    marginTop: "-.5rem",
    marginLeft: "1rem",
  },
}
