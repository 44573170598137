import { styles } from "./styles"
import { useTranslation } from "react-i18next"
import { useSampleTexts } from "./constants"

interface NoMessagesProps {
  clientHeight: number | null
  sampleTextIndex: number
  fadeState: boolean
}

const NoMessages: React.FC<NoMessagesProps> = ({
  clientHeight,
  sampleTextIndex,
  fadeState,
}) => {
  const { t } = useTranslation()
  const SAMPLE_TEXTS = useSampleTexts()

  return (
    <div className="no-messages" style={styles.noMessagesHeight(clientHeight)}>
      <div className="no-messages-label-parent" style={{ marginTop: "auto" }}>
        <div className="no-messages-title" style={styles.sampleText(fadeState)}>
          {SAMPLE_TEXTS[sampleTextIndex]}
        </div>
      </div>
      <div className="no-messages-label-parent" style={{ marginTop: "auto" }}>
        <label className="skapa-label">
          <span>{t("help:emptyChat:title")}:</span> {t("help:emptyChat:body")}
        </label>
      </div>
    </div>
  )
}

export default NoMessages
