import Modal, { Sheets, ModalBody } from "@ingka/modal"
import { SheetViewProps } from "features/insights/types"
import { useState, useEffect } from "react"

const SheetView: React.FC<SheetViewProps> = ({
  visible,
  handleClose,
  content,
  footer = null,
  details,
}) => {
  const [modalSize, setModalSize] = useState<"small" | "medium">("medium")

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1600) {
        setModalSize("small")
      } else {
        setModalSize("medium")
      }
    }

    handleResize()

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div className="sheet-parent">
      <Modal
        visible={visible}
        handleCloseBtn={handleClose}
        escapable={true}
        focusLockProps={{ locked: false }}
      >
        <Sheets
          labelledById="sheetView"
          alignment="left" /* left | right */
          preserveAlignment={false} /* affects mobile view only */
          size={modalSize} /* small | medium | large */
          footer={footer}
          header={<></>}
        >
          <ModalBody
            style={{
              display: "flex",
              flexDirection: "column",
              height: "calc(100vh - 3rem)",
            }}
          >
            <div className="sheet-title"></div>
            {content}
          </ModalBody>
        </Sheets>
        {details}
      </Modal>
    </div>
  )
}

export default SheetView
