import Button from "@ingka/button"
import { useAppDispatch } from "app/hooks"
import { useNavigate } from "react-router-dom"
import { toggleFilterMenu } from "features/listConversations/listConversationSlice"

interface FilterButtonProps {
  navigateToRoot?: boolean
}

const FilterButton: React.FC<FilterButtonProps> = ({
  navigateToRoot = false,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const toggleFiltersNavigate = () => {
    navigate("/")
    setTimeout(() => {
      dispatch(toggleFilterMenu())
    }, 50)
  }

  return (
    <Button
      small={true}
      type="secondary"
      onClick={
        navigateToRoot
          ? () => toggleFiltersNavigate()
          : () => dispatch(toggleFilterMenu())
      }
    >
      Edit filters
    </Button>
  )
}

export default FilterButton
