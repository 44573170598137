import { useFetchConversations } from "./hooks/useFetchConversations"
import { useSetInitialSelectedConversation } from "./hooks/useSetInitialSelectedConversation"
import { useFetchLabels } from "./hooks/useFetchLabels"
import Grid from "@mui/material/Grid"
import { useAppSelector } from "app/hooks"
import InfoCard from "components/InfoCard"
import ConversationDetails from "../messages/ConversationDetails"
import ConversationFilterMenu from "./ConversationFilterMenu"
import FiltersResultsSection from "./components/FiltersResultsSection"
import { RootState } from "app/store"
import { useFetchFlags } from "../flag/hooks/useFetchFlags"
import FilterButton from "features/insights/components/buttons/FilterButton"

const ListConversations: React.FC = () => {
  const listConversationState = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  useFetchConversations()
  useSetInitialSelectedConversation()
  useFetchLabels()
  useFetchFlags()

  const filterHelpAction = (
    <>
      You need to at least filter on a time frame and country
      <ConversationFilterMenu />
      <div style={{ width: "100%", marginTop: "1.5rem" }}>
        <FilterButton />
      </div>
    </>
  )

  const filterHelp = (
    <Grid container justifyContent="center" alignItems="center" height="90vh">
      <InfoCard text={filterHelpAction} header="No filters" />
    </Grid>
  )

  const viewConversations = (
    <div className="page-contents-narrow two-pane-row">
      <FiltersResultsSection />
      <ConversationDetails />
    </div>
  )

  const showFilterHelp =
    !listConversationState.filters.length &&
    !listConversationState.loadingConversations

  return showFilterHelp ? filterHelp : viewConversations
}

export default ListConversations
