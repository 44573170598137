import { Skeleton, TextField } from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { setCurrentMessage, setPrompt } from "./insightsSlice"
import he from "he"
import { ReactElement } from "react"
import useInsightsHelperFunctions from "./utils/hooks/useInsightsHelperFunctions"

interface PromptInputProps {
  type: "insight" | "summary" | "chat"
  promptInfo?: ReactElement | undefined
}

const PromptInput: React.FC<PromptInputProps> = ({ type, promptInfo }) => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { inputDisabled } = useInsightsHelperFunctions()

  const selectedPrompt =
    type === "insight"
      ? insightsState.selectedInsightPrompt
      : type === "summary"
        ? insightsState.selectedSummaryPrompt
        : type === "chat"
          ? insightsState.selectedSummaryPrompt
          : insightsState.selectedSummaryPrompt

  return (
    <>
      {insightsState.promptsLoading ? (
        <Skeleton variant="rectangular" width="100%" height={300} />
      ) : (
        <>
          <div style={{ position: "relative" }}>
            <TextField
              id="prompt-textarea"
              disabled={inputDisabled}
              multiline
              fullWidth
              minRows={type === "chat" ? 5 : 10}
              value={he.decode(selectedPrompt.prompt)}
              onChange={(e) => {
                if (type === "insight" || type === "summary") {
                  dispatch(
                    setPrompt({
                      prompt: he.encode(e.target.value),
                      promptType: type,
                    }),
                  )
                } else {
                  dispatch(setCurrentMessage(he.encode(e.target.value)))
                }
              }}
            />
          </div>
          <div
            style={{
              margin: "-2rem 0 3.25rem 1rem",
              position: "relative",
              zIndex: "2",
              width: "100%",
            }}
          >
            {promptInfo}
          </div>
        </>
      )}
    </>
  )
}

export default PromptInput
