import InsightsMessage from "features/insights/InsightsMessage"
import { CSSTransition } from "react-transition-group"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"

const HistoryChatView = () => {
  const insightsState = useAppSelector((state: RootState) => state.insights)

  const visible = insightsState.chatHistoryVisible
  const { previewedThread } = useInsightsHelperFunctions()
  const emptyChat =
    previewedThread &&
    previewedThread.chatMessages.length === 1 &&
    previewedThread.chatMessages[0].type === "default-sample"

  const renderChatMessages = () => {
    if (emptyChat || !previewedThread) {
      return (
        <div className="no-messages-label-parent" style={{ margin: "0" }}>
          <div className="no-messages-title">No archived chat selected</div>
        </div>
      )
    }

    return (
      <div className="chat" style={{ paddingBottom: "2rem" }}>
        {previewedThread?.chatMessages.map((message, messageIndex) => (
          <InsightsMessage
            key={messageIndex}
            messageContent={message.content}
            messageEditState={false}
            type={message.type}
            toolbar={null}
            button={<></>}
          />
        ))}
      </div>
    )
  }

  return (
    <CSSTransition in={visible} timeout={250} classNames="slide" unmountOnExit>
      <div
        className={`modal-details-panel-parent ${
          emptyChat || !previewedThread ? "collapsed" : "visible"
        }`}
      >
        <div className="overflow-hider">
          <div className="modal-details-panel">{renderChatMessages()}</div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default HistoryChatView
