import { useState } from "react"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import MessagesSkeleton from "./MessagesSkeleton"
import "./messageList.css"
import MessageOptions from "./MessageOptions"
import MessageItem from "./MessageItem"

const ConversationDetails: React.FC = () => {
  const { loadingConversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const { messages } = useAppSelector((state: RootState) => state.messages)
  const [showOriginalText, setShowOriginalText] = useState<boolean>(true)
  const [hideAutomatedMessages, setHideAutomatedMessages] =
    useState<boolean>(true)

  const handleTranslationToggle = () => {
    setShowOriginalText((prevState) => !prevState)
  }

  const handleAutomatedMessagesToggle = () => {
    setHideAutomatedMessages((prevState) => !prevState)
  }

  const conversation = messages.map((message, index) => (
    <MessageItem
      key={index}
      message={message}
      index={index}
      showOriginalText={showOriginalText}
      hideAutomatedMessages={hideAutomatedMessages}
    />
  ))

  return (
    <div className="conversation-list-parent">
      <MessageOptions
        showOriginalText={showOriginalText}
        handleTranslationToggle={handleTranslationToggle}
        handleAutomatedMessagesToggle={handleAutomatedMessagesToggle}
      />
      <div
        className="flex-column"
        style={{ overflowY: "auto", paddingLeft: "2rem" }}
      >
        {loadingConversations ? <MessagesSkeleton /> : conversation}
      </div>
    </div>
  )
}

export default ConversationDetails
