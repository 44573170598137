import {
  format,
  isToday,
  isYesterday,
  subDays,
} from "date-fns"
import { enGB } from "date-fns/locale"

export const dateFormatter = (timestamp: number) => {
  const date = new Date(timestamp)
  const now = new Date()

  const previousSameWeekday = subDays(now, 7)

  if (isToday(date)) {
    return `Today ${format(date, "HH:mm", { locale: enGB })}`
  }

  if (isYesterday(date)) {
    return `Yesterday ${format(date, "HH:mm", { locale: enGB })}`
  }

  if (date > previousSameWeekday) {
    return `${format(date, "EEEE", { locale: enGB })} ${format(date, "HH:mm", { locale: enGB })}`
  }

  return `${format(date, "MMMM dd", { locale: enGB })} ${format(date, "HH:mm", { locale: enGB })}`
}
