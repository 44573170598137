import { Skeleton } from "@mui/material"

const ConversationsSkeleton = () => {
  return (
    <div className="flex-column" style={{ gap: "1rem", padding: "1rem 0" }}>
      <div>
        <Skeleton
          variant="rounded"
          width={"40%"}
          height={40}
          style={{ marginBottom: "1rem" }}
        />
        <Skeleton variant="rounded" width={"100%"} height={200} />
      </div>
      <div>
        <Skeleton
          variant="rounded"
          width={"40%"}
          height={40}
          style={{ marginBottom: "1rem" }}
        />
        <Skeleton variant="rounded" width={"100%"} height={200} />
      </div>
      <div>
        <Skeleton
          variant="rounded"
          width={"40%"}
          height={40}
          style={{ marginBottom: "1rem" }}
        />
        <Skeleton variant="rounded" width={"100%"} height={200} />
      </div>
      <div>
        <Skeleton
          variant="rounded"
          width={"40%"}
          height={40}
          style={{ marginBottom: "1rem" }}
        />
        <Skeleton variant="rounded" width={"100%"} height={200} />
      </div>
      <div>
        <Skeleton
          variant="rounded"
          width={"40%"}
          height={40}
          style={{ marginBottom: "1rem" }}
        />
        <Skeleton variant="rounded" width={"100%"} height={200} />
      </div>
      <div>
        <Skeleton
          variant="rounded"
          width={"40%"}
          height={40}
          style={{ marginBottom: "1rem" }}
        />
        <Skeleton variant="rounded" width={"100%"} height={200} />
      </div>
    </div>
  )
}

export default ConversationsSkeleton
