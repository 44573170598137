import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { conversationInsights, insightsSummary } from "app/api"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
} from "app/firebase"
import {
  LLMModel,
  InsightPromptRequest,
  InsightsState,
  PromptType,
  SavedPrompt,
  SummaryPromptRequest,
  Message,
  ChatThread,
} from "./types"
import { insightsModels } from "./insightConsts"
import {
  loadChatThreads,
  loadSelectedThread,
  saveChatThreads,
  saveSelectedThread,
} from "./utils/storageUtils"

// Async thunks
export const fetchConversationInsights = createAsyncThunk(
  "insights/fetchConversationInsights",
  async (req: InsightPromptRequest) => {
    const result = await conversationInsights(req)
    return result.data
  },
)

export const fetchInsightsSummary = createAsyncThunk(
  "insights/fetchInsightsSummary",
  async (req: SummaryPromptRequest) => {
    const result = await insightsSummary(req)
    return result ? result.data : "Error fetching result"
  },
)

export const fetchSavedPrompts = createAsyncThunk(
  "insights/fetchSavedPrompts",
  async ({
    collectionIdentifier,
    systemPrompts,
  }: {
    collectionIdentifier: string
    systemPrompts?: boolean
  }) => {
    const [summarySnap, insightsSnap] = await Promise.all([
      getDocs(
        collection(
          "prompts",
          systemPrompts ? "summarySystem" : "summary",
          collectionIdentifier,
        ),
      ),
      getDocs(
        collection(
          "prompts",
          systemPrompts ? "insightSystem" : "insight",
          collectionIdentifier,
        ),
      ),
    ])

    const summaryPrompts: SavedPrompt[] = summarySnap.map(
      (doc) =>
        ({
          ...doc.data,
          id: doc.id,
        }) as SavedPrompt,
    )

    const insightsPrompts: SavedPrompt[] = insightsSnap.map(
      (doc) =>
        ({
          ...doc.data,
          id: doc.id,
        }) as SavedPrompt,
    )

    return { summaryPrompts, insightsPrompts }
  },
)

export const deleteSavedPrompt = createAsyncThunk(
  "insights/deleteSavedPrompt",
  async ({
    collectionIdentifier,
    promptType,
    promptId,
    systemPrompt,
  }: {
    collectionIdentifier: string
    promptType: PromptType
    promptId: string
    systemPrompt?: boolean
  }) => {
    await deleteDoc(
      doc(
        "prompts",
        systemPrompt ? `${promptType}System` : promptType,
        collectionIdentifier,
        promptId,
      ),
    )
    return { promptType, promptId }
  },
)

export const addSavedPrompt = createAsyncThunk(
  "insights/addSavedPrompt",
  async ({
    collectionIdentifier,
    promptType,
    prompt,
    systemPrompt,
  }: {
    collectionIdentifier: string
    promptType: PromptType
    prompt: SavedPrompt
    systemPrompt?: boolean
  }) => {
    const docRef = await addDoc(
      collection(
        "prompts",
        systemPrompt ? `${promptType}System` : promptType,
        collectionIdentifier,
      ),
      prompt,
    )

    if (!docRef) return

    return {
      promptType,
      savedPrompt: { ...prompt, id: docRef.id },
    }
  },
)

export const saveCurrentPrompt = createAsyncThunk(
  "insights/saveCurrentPrompt",
  async ({
    collectionIdentifier,
    prompt,
    promptType,
    systemPrompt,
  }: {
    collectionIdentifier: string
    prompt: SavedPrompt
    promptType: PromptType
    systemPrompt?: boolean
  }) => {
    await setDoc(
      doc(
        "prompts",
        systemPrompt ? `${promptType}System` : promptType,
        collectionIdentifier,
        prompt.id,
      ),
      prompt,
    )
    return { promptType, prompt }
  },
)

// Initial state
export const initialState: InsightsState = {
  insights: {},
  insightsSummary: "",
  insightsSummaryCost: 0,
  insightsLoading: false,
  insightsSummaryLoading: false,
  summaryPromptIsSaved: true,
  insightPromptIsSaved: true,
  chatPromptIsSaved: true,
  isSaving: false,
  promptsLoading: false,
  selectedSummaryPrompt: {
    displayName: "Browse prompts",
    id: "723aeeeb-6b2f-471d-b4ee-ff7c2a12c2a0",
    prompt: "",
    model: "GPT-4o",
    amount: 20,
  },
  selectedInsightPrompt: {
    displayName: "Browse prompts",
    id: "723aeeeb-6b2f-471d-b4ee-ff7c2a12c2a0",
    prompt: "",
    model: "GPT-4o Mini",
    amount: 200,
  },
  chatMessages: [],
  currentChatMessage: {
    displayName: "",
    id: "initial-prompt",
    prompt: "",
    model: "GPT-4o Mini",
    amount: 0,
  },
  messageEditIndex: null,
  advancedControlsSheetVisible: false,
  advancedControlsVisible: true,
  tabIndex: 0,
  animateButton: false,
  chatHistoryVisible: false,
  conversationsViewVisible: false,
  chatThreads: [],
  selectedThread: "",
  previewedThread: "",
  insightsDataShare: 75,
  insightsModelConfig: insightsModels,
  largeContextWindowEnabled: false,
  tokensPerConversation: 500,
  settingsVisible: false,
  selectedChatModel: "GPT-4o Mini",
  quotaError: false,
}

// Chat thread helper functions
const getCurrentThread = (state: InsightsState): ChatThread | undefined =>
  Array.isArray(state.chatThreads)
    ? state.chatThreads.find((thread) => thread.uuid === state.selectedThread)
    : undefined

const updateTimestampThread = (thread: ChatThread) =>
  (thread.lastInteraction = Date.now())

const commitThreadsUpdate = (
  state: InsightsState,
  currentThread?: ChatThread,
) => {
  const quotaExceeded = !saveChatThreads(state.chatThreads)
  state.quotaError = quotaExceeded

  if (state.quotaError) return
  currentThread && updateTimestampThread(currentThread)
}

// Slice
const insightsSlice = createSlice({
  name: "insights",
  initialState: {
    ...initialState,
    chatThreads: loadChatThreads(),
    selectedThread: loadSelectedThread(),
    previewedThread: loadSelectedThread(),
  },
  reducers: {
    setModel: (
      state,
      action: PayloadAction<{ model: LLMModel; promptType: PromptType }>,
    ) => {
      const { model, promptType } = action.payload
      const selectedPrompt =
        promptType === "summary"
          ? state.selectedSummaryPrompt
          : state.selectedInsightPrompt
      selectedPrompt.model = model
      state[`${promptType}PromptIsSaved`] = false
      state.largeContextWindowEnabled = false
    },
    setPrompt: (
      state,
      action: PayloadAction<{ prompt: string; promptType: PromptType }>,
    ) => {
      const { prompt, promptType } = action.payload
      const selectedPrompt =
        promptType === "summary"
          ? state.selectedSummaryPrompt
          : state.selectedInsightPrompt
      selectedPrompt.prompt = prompt
      state[`${promptType}PromptIsSaved`] = false
    },
    setTempPrompt: (
      state,
      action: PayloadAction<{ prompt: string; promptType: PromptType }>,
    ) => {
      const { prompt, promptType } = action.payload
      const selectedPrompt =
        promptType === "summary"
          ? state.selectedSummaryPrompt
          : state.selectedInsightPrompt
      selectedPrompt.prompt = prompt
      state[`${promptType}PromptIsSaved`] = false
    },
    setAmount: (
      state,
      action: PayloadAction<{ amount: number; promptType: PromptType }>,
    ) => {
      const { amount, promptType } = action.payload
      const selectedPrompt =
        promptType === "summary"
          ? state.selectedSummaryPrompt
          : state.selectedInsightPrompt
      selectedPrompt.amount = amount
      state[`${promptType}PromptIsSaved`] = false
    },
    setSelectedPrompt: (
      state,
      action: PayloadAction<{ prompt: SavedPrompt; promptType: PromptType }>,
    ) => {
      const { prompt, promptType } = action.payload

      const capitalPromptType = (promptType.charAt(0).toUpperCase() +
        promptType.slice(1)) as "Insight" | "Summary"

      state[`selected${capitalPromptType}Prompt`] = prompt
      state[`${promptType}PromptIsSaved`] = true
    },
    resetInsights: (state) => {
      state.insights = {}
    },
    resetInsightsAndSummary: (state) => {
      state.insights = {}
      state.insightsSummary = ""
    },
    appendMessage: (state, action: PayloadAction<Message>) => {
      const currentThread = getCurrentThread(state)
      if (!currentThread) return

      currentThread.chatMessages = [
        ...currentThread.chatMessages,
        action.payload,
      ]

      commitThreadsUpdate(state, currentThread)
    },
    removeMessage: (state, action: PayloadAction<number>) => {
      const currentThread = getCurrentThread(state)
      if (!currentThread) return

      const index = action.payload
      const chatMessages = currentThread.chatMessages

      if (index >= 0 && index < chatMessages.length) {
        chatMessages.splice(index, 1)

        commitThreadsUpdate(state, currentThread)
      }
    },
    popMessages: (state, action: PayloadAction<number>) => {
      const currentThread = getCurrentThread(state)
      if (!currentThread) return

      const index = action.payload
      const chatMessages = currentThread.chatMessages

      if (index >= 0 && index < chatMessages.length) {
        currentThread.chatMessages = chatMessages.slice(0, index)

        commitThreadsUpdate(state, currentThread)
      }
    },
    setCurrentMessage: (state, action: PayloadAction<string>) => {
      state.currentChatMessage.prompt = action.payload
    },
    resetMessages: (state) => {
      const currentThread = getCurrentThread(state)
      if (!currentThread) return

      currentThread.chatMessages = []
      commitThreadsUpdate(state, currentThread)
    },
    setMessageEditIndex: (state, action: PayloadAction<number | null>) => {
      state.messageEditIndex = action.payload
    },
    setAdvancedControlsSheetVisible: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.advancedControlsSheetVisible = action.payload
    },
    setAdvancedControlsVisible: (state, action: PayloadAction<boolean>) => {
      state.advancedControlsVisible = action.payload
    },
    setTabIndex: (state, action: PayloadAction<number>) => {
      state.tabIndex = action.payload
    },
    setAnimateButton: (state, action: PayloadAction<boolean>) => {
      state.animateButton = action.payload
    },
    setChatHistoryVisibility: (state, action: PayloadAction<boolean>) => {
      state.chatHistoryVisible = action.payload
    },
    setConversationsViewVisibility: (state, action: PayloadAction<boolean>) => {
      state.conversationsViewVisible = action.payload
    },
    createThread: (state, action: PayloadAction<ChatThread>) => {
      if (!Array.isArray(state.chatThreads)) return

      state.chatThreads = [...state.chatThreads, action.payload]
      state.selectedThread = action.payload.uuid

      commitThreadsUpdate(state)
      saveSelectedThread(action.payload.uuid)
    },
    removeThread: (state, action: PayloadAction<string>) => {
      if (!Array.isArray(state.chatThreads)) {
        state.chatThreads = []
      }
      const threadIndex = state.chatThreads.findIndex(
        (thread) => thread.uuid === action.payload,
      )
      if (threadIndex === -1 || state.selectedThread === action.payload) return

      state.chatThreads = [
        ...state.chatThreads.slice(0, threadIndex),
        ...state.chatThreads.slice(threadIndex + 1),
      ]

      commitThreadsUpdate(state)
    },
    setSelectedThread: (state, action: PayloadAction<string>) => {
      state.selectedThread = action.payload
      saveSelectedThread(action.payload)
    },
    setPreviewedThread: (state, action: PayloadAction<string>) => {
      state.previewedThread = action.payload
    },
    setInsightsDataShare: (state, action: PayloadAction<number>) => {
      state.insightsDataShare = action.payload
    },
    toggleLargeContextWindow: (state) => {
      state.largeContextWindowEnabled = !state.largeContextWindowEnabled
    },
    setTokensPerConversation: (state, action: PayloadAction<number>) => {
      state.tokensPerConversation = action.payload
    },
    setSettingsVisibility: (state, action: PayloadAction<boolean>) => {
      state.settingsVisible = action.payload
    },
    setSelectedChatModel: (state, action: PayloadAction<LLMModel>) => {
      const currentThread = getCurrentThread(state)
      if (!currentThread) return

      currentThread.model = action.payload
      state.selectedChatModel = action.payload

      commitThreadsUpdate(state)
    },
    setQuotaError: (state, action: PayloadAction<boolean>) => {
      state.quotaError = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversationInsights.pending, (state) => {
        state.insightsLoading = true
      })
      .addCase(fetchConversationInsights.fulfilled, (state, action) => {
        state.insightsLoading = false
        state.insights = action.payload ?? {}

        const currentThread = getCurrentThread(state)
        if (!currentThread) return

        currentThread.chatMessages = [
          ...currentThread.chatMessages,
          { content: action.payload, type: "data" },
        ]

        commitThreadsUpdate(state)
      })
      .addCase(fetchConversationInsights.rejected, (state) => {
        state.insightsLoading = false
        state.insights = {}
      })
      .addCase(fetchInsightsSummary.pending, (state) => {
        state.insightsSummaryLoading = true
      })
      .addCase(fetchInsightsSummary.fulfilled, (state, action) => {
        state.insightsSummaryLoading = false
        state.insightsSummary = action.payload ? action.payload["analysis"] : ""
        state.insightsSummaryCost = action.payload
          ? action.payload["total_cost"]?.toFixed(6)
          : 0

        const currentThread = getCurrentThread(state)
        if (!currentThread) return

        currentThread.chatMessages = [
          ...currentThread.chatMessages,
          { content: action.payload.analysis, type: "received" },
        ]

        commitThreadsUpdate(state)
      })
      .addCase(fetchInsightsSummary.rejected, (state) => {
        state.insightsSummaryLoading = false
      })
      .addCase(fetchSavedPrompts.pending, (state) => {
        state.promptsLoading = true
      })
      .addCase(fetchSavedPrompts.fulfilled, (state, action) => {
        const insightsPrompts = action.payload.insightsPrompts || []
        state.savedInsightsPrompts = insightsPrompts.map(
          (prompt) =>
            ({
              ...prompt,
              model: prompt.model ?? "GPT-4o",
              amount: prompt.amount ?? 20,
            }) as SavedPrompt,
        )
        const summaryPrompts = action.payload.summaryPrompts || []
        state.savedSummaryPrompts = summaryPrompts.map(
          (prompt) =>
            ({
              ...prompt,
              model: prompt.model ?? "GPT-4o",
              amount: prompt.amount ?? 20,
            }) as SavedPrompt,
        )
        state.promptsLoading = false
      })
      .addCase(fetchSavedPrompts.rejected, (state) => {
        state.savedInsightsPrompts = []
        state.savedSummaryPrompts = []
        state.selectedInsightPrompt = initialState.selectedInsightPrompt
        state.selectedSummaryPrompt = initialState.selectedSummaryPrompt
        state.promptsLoading = false
      })
      .addCase(addSavedPrompt.pending, (state) => {
        state.isSaving = true
      })
      .addCase(addSavedPrompt.fulfilled, (state, action) => {
        if (action.payload?.promptType === "insight") {
          state.savedInsightsPrompts = [
            ...(state.savedInsightsPrompts || []),
            action.payload.savedPrompt,
          ]
          state.selectedInsightPrompt = action.payload.savedPrompt
          state.insightPromptIsSaved = true
        } else if (action.payload?.promptType === "summary") {
          state.savedSummaryPrompts = [
            ...(state.savedSummaryPrompts || []),
            action.payload.savedPrompt,
          ]
          state.selectedSummaryPrompt = action.payload.savedPrompt
          state.summaryPromptIsSaved = true
        }
        state.isSaving = false
      })
      .addCase(addSavedPrompt.rejected, (state) => {
        state.isSaving = false
      })
      .addCase(deleteSavedPrompt.pending, (state) => {
        state.isSaving = true
      })
      .addCase(deleteSavedPrompt.fulfilled, (state, action) => {
        const { promptType, promptId } = action.payload
        if (promptType === "insight") {
          state.savedInsightsPrompts = (
            state.savedInsightsPrompts || []
          ).filter((p) => p.id !== promptId)
          if (state.selectedInsightPrompt.id === promptId) {
            state.selectedInsightPrompt =
              state.savedInsightsPrompts[0] ||
              initialState.selectedInsightPrompt
          }
        } else if (promptType === "summary") {
          state.savedSummaryPrompts = (state.savedSummaryPrompts || []).filter(
            (p) => p.id !== promptId,
          )
          if (state.selectedSummaryPrompt.id === promptId) {
            state.selectedSummaryPrompt =
              state.savedSummaryPrompts[0] || initialState.selectedSummaryPrompt
          }
        }
        state.isSaving = false
      })
      .addCase(deleteSavedPrompt.rejected, (state) => {
        state.isSaving = false
      })
      .addCase(saveCurrentPrompt.pending, (state) => {
        state.isSaving = true
      })
      .addCase(saveCurrentPrompt.fulfilled, (state, action) => {
        const { promptType, prompt } = action.payload
        if (promptType === "insight") {
          state.insightPromptIsSaved = true
          state.savedInsightsPrompts = (state.savedInsightsPrompts || []).map(
            (p) => (p.id === prompt.id ? prompt : p),
          )
        } else if (promptType === "summary") {
          state.summaryPromptIsSaved = true
          state.savedSummaryPrompts = (state.savedSummaryPrompts || []).map(
            (p) => (p.id === prompt.id ? prompt : p),
          )
        }
        state.isSaving = false
      })
      .addCase(saveCurrentPrompt.rejected, (state) => {
        state.isSaving = false
      })
  },
})

export const {
  setModel,
  setAmount,
  setPrompt,
  setSelectedPrompt,
  resetInsights,
  resetInsightsAndSummary,
  appendMessage,
  removeMessage,
  popMessages,
  setCurrentMessage,
  resetMessages,
  setMessageEditIndex,
  setAdvancedControlsSheetVisible,
  setAdvancedControlsVisible,
  setTabIndex,
  setAnimateButton,
  setChatHistoryVisibility,
  setConversationsViewVisibility,
  createThread,
  removeThread,
  setSelectedThread,
  setPreviewedThread,
  setInsightsDataShare,
  toggleLargeContextWindow,
  setTokensPerConversation,
  setSettingsVisibility,
  setSelectedChatModel,
  setQuotaError,
} = insightsSlice.actions

export default insightsSlice.reducer
