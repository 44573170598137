import InlineMessage from "@ingka/inline-message"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { KeyValueType } from "app/types"
import { ChangeEvent, useCallback, useMemo } from "react"
import { purify } from "utils/sanitize"
import ConversationItem from "../ConversationItem"
import { fetchConversations, resetErrorMessage } from "../listConversationSlice"
import "../styles/conversationList.css"
import ConversationFilterMenu from "../ConversationFilterMenu"
import { useTranslation } from "react-i18next"
import Filters from "../Filters"
import FilterButton from "features/insights/components/buttons/FilterButton"
import FiltersHeader from "./FiltersHeader"
import ResultsList from "./ResultsList"

const FiltersResultsSection: React.FC = ({}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { insights } = useAppSelector((state: RootState) => state.insights)
  const listConversationState = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const conversations = listConversationState.conversations ?? []
  const showManualTag = listConversationState.filters.some(
    (filter) => filter.key === "manual_tag_label_id",
  )
  const conversationSummary = t("text:conversationSummary", {
    conversationsLength: conversations.length,
    totalConversations: listConversationState.totalAmountOfConversations,
  })

  const errorMessage = (
    <div className="overlay-container">
      <InlineMessage
        body={listConversationState.errorMessage}
        title="Couldn't fetch conversations"
        variant="cautionary"
        dismissable
        onDismissClick={() => dispatch(resetErrorMessage())}
      />
    </div>
  )

  const renderConversationItems = useMemo(() => {
    return conversations.map((conversation) => (
      <div key={conversation.id}>
        <ConversationItem
          showManualTagId={showManualTag}
          conversation={conversation}
          insight={insights[conversation.id]}
        />
      </div>
    ))
  }, [conversations, insights, showManualTag])

  const handlePaginationChange = useCallback(
    (_: ChangeEvent<unknown>, page: number) => {
      const pageParam: KeyValueType = {
        key: "page",
        value: purify(page),
      }
      const updatedFilters = [...listConversationState.filters, pageParam]
      dispatch(fetchConversations(updatedFilters))
    },
    [listConversationState.filters, dispatch],
  )

  return (
    <>
      <div className="conversation-list-parent">
        <FiltersHeader
          title={t("titles:viewConversation")}
          summary={conversationSummary}
        />
        <Filters button={<FilterButton />} />
        <ResultsList
          totalAmountOfConversations={
            listConversationState.totalAmountOfConversations
          }
          renderConversationItems={renderConversationItems}
          handlePaginationChange={handlePaginationChange}
        />
      </div>
      <ConversationFilterMenu />

      {listConversationState.errorMessage && errorMessage}
    </>
  )
}

export default FiltersResultsSection
