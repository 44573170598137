import { PromptControlProps } from "features/insights/types"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import FeatureTitle from "components/FeatureTitle"
import Tabs, { Tab, TabPanel } from "@ingka/tabs"
import LLMProcessing from "../input/LLMProcessing"
import RawDataControls from "../input/RawDataControls"

const AdvancedView = ({ promptCrud }: PromptControlProps) => {
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const advancedControlsDisabled = !insightsState.advancedControlsVisible

  const tabs = [
    <Tab key="tab-1" text={"Raw data controls"} tabPanelId="tab_1" />,
    <Tab key="tab-2" text={"LLM pre-processing"} tabPanelId="tab_2" />,
  ]

  const tabPanels = [
    <TabPanel key="tab-1" tabPanelId="tab_1">
      <RawDataControls />
    </TabPanel>,
    <TabPanel key="tab-2" tabPanelId="tab_2">
      <LLMProcessing promptCrud={promptCrud} />
    </TabPanel>,
  ]

  return (
    <div style={{ position: "relative", height: "inherit" }}>
      <div
        className={`${advancedControlsDisabled ? "unfocused-content controls-parent" : "controls-parent"}`}
      >
        <FeatureTitle featureName="Configure data" />
        <Tabs tabs={tabs} tabPanels={tabPanels} defaultActiveTab="tab_1" />
      </div>
    </div>
  )
}

export default AdvancedView
