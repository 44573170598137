import { useEffect, useRef } from "react"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { mostRecentThread, currentFilterStoredThreads } from "../chatUtils"
import { setQuotaError, setSelectedThread } from "../../insightsSlice"
import { loadChatThreads } from "../storageUtils"
import { InsightsUtilsRunners } from "../llmUtils/llmUtilRunners"
import { useTranslation } from "react-i18next"
import { KeyValueType } from "app/types"

export function useConversationThreads() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { conversations, filters } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const hasAppliedInitialData = useRef(false)
  const { runChatCreate } = InsightsUtilsRunners()

  const hasDispatched = useRef(false)
  const previousFiltersRef = useRef<KeyValueType[] | null>(null)

  const newChat = () => {
    if (!hasAppliedInitialData.current) {
      hasAppliedInitialData.current = true
      runChatCreate(false)
    }

    if (
      previousFiltersRef.current &&
      JSON.stringify(previousFiltersRef.current) !== JSON.stringify(filters)
    ) {
      window.alert(t("alerts:changedFilters"))
    }

    previousFiltersRef.current = filters
  }

  useEffect(() => {
    if (!conversations || !filters) return

    if (previousFiltersRef.current === null) {
      previousFiltersRef.current = filters
    }

    const threads = loadChatThreads()

    if (!Array.isArray(threads) || threads.length === 0) {
      hasAppliedInitialData.current = false
    }

    const savedThreadsMatchedToCurrentFilters = currentFilterStoredThreads(
      filters,
      threads,
    )
    const lastThreadForCurrentFilters = mostRecentThread(
      savedThreadsMatchedToCurrentFilters,
      threads,
    )

    const chatThreadExists =
      insightsState.selectedThread !== "" &&
      threads.length > 0 &&
      savedThreadsMatchedToCurrentFilters.length > 0

    if (!chatThreadExists) {
      newChat()
      return
    }

    const restoredThread = threads[lastThreadForCurrentFilters].uuid
    dispatch(setSelectedThread(restoredThread))
  }, [conversations, filters])

  useEffect(() => {
    if (insightsState.quotaError) {
      if (!hasDispatched.current) {
        window.alert(t("alerts:quotaExceeded"))
        dispatch(setQuotaError(false))
        hasDispatched.current = true
      }
    } else {
      hasDispatched.current = false
    }
  }, [insightsState.quotaError])

  return { hasAppliedInitialData }
}
