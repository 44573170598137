import { List, ListItem, Skeleton } from "@mui/material"
import Text from "@ingka/text"
import Grid from "@mui/material/Grid"

function getRandomInteger(min: number, max: number): number {
  // Generate a random integer between min and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function createRandomArray(): number[] {
  // Generate a random length between 5 and 20
  const randomNumber = getRandomInteger(5, 20)

  // Create an array and fill it with index values
  const randomArray: number[] = []
  for (let i = 0; i < randomNumber; i++) {
    randomArray.push(i)
  }

  return randomArray
}

const randomArray = createRandomArray()

const MessagesSkeleton = () => {
  return (
    <List
      style={{
        height: "100%",
        overflowY: "hidden",
        width: "100%",
      }}
    >
      {randomArray.map((i: number) => (
        <ListItem key={`message:${i}`}>
          <Grid columnSpacing={1} item container xs={12}>
            <Grid item xs={1} className="centerContent">
              <Text tagName="p">{i}</Text>
            </Grid>
            <Grid item xs={8}>
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={30 + 10 * getRandomInteger(1, 5)}
              />
            </Grid>
            <Grid item xs={2} className="centerContent">
              <Skeleton variant="circular" width={50} height={50} />
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </List>
  )
}

export default MessagesSkeleton
