import ToggleLargeContext from "features/insights/ToggleLargeContext"
import RawDataInformation from "./RawDataInformation"
import Button from "@ingka/button"
import InlineMessage from "@ingka/inline-message"
import informationCircle from "@ingka/ssr-icon/paths/information-circle"
import InitialDataSizeSelection from "./InitialDataSizeSelection"
import TokensPerConvSelection from "./TokensPerConvSelection"
import { useTokenEstimates } from "features/insights/utils/hooks/useTokenEstimates"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import { useTranslation } from "react-i18next"

const RawDataControls = () => {
  const { inputDisabled } = useInsightsHelperFunctions()
  const { handleAlterRawData } = useTokenEstimates()
  const { t } = useTranslation()

  // Currently disabled functionality until it's been tested more
  const disabledForUsers = true
  const experimentalFeatures = !disabledForUsers && (
    <>
      <ToggleLargeContext />
      <InitialDataSizeSelection />
    </>
  )

  return (
    <div className="flex-column" style={{ gap: "2rem" }}>
      <InlineMessage
        title={t("warnings:rawDataProcessing:title")}
        body={t("warnings:rawDataProcessing:body")}
        variant="informative"
        ssrIcon={informationCircle}
      />

      <div className="modal-section-parent">
        <div className="flex-row spaced">
          <h4>Data formatting</h4>
          <Button
            type="emphasised"
            disabled={inputDisabled}
            onClick={() => handleAlterRawData()}
            small={true}
          >
            Format data
          </Button>
        </div>

        <div className="component-scaler" style={{ margin: "-1.5rem 0" }}>
          {experimentalFeatures}
          <TokensPerConvSelection />
          <RawDataInformation />
        </div>
      </div>
    </div>
  )
}

export default RawDataControls
