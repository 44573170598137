import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import {
  setChatHistoryVisibility,
  setPreviewedThread,
} from "features/insights/insightsSlice"
import Button from "@ingka/button"

const ChatReturnButton = () => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)

  return (
    <div>
      <Button
        type="emphasised"
        small={true}
        onClick={() => {
          dispatch(setPreviewedThread(insightsState.selectedThread))
          dispatch(setChatHistoryVisibility(false))
        }}
      >
        Return to current chat
      </Button>
    </div>
  )
}

export default ChatReturnButton
