export const logStorageEstimate = async (): Promise<void> => {
  try {
    if (!navigator.storage) {
      console.log("Storage API not supported")
      return
    }

    const { usage, quota } = await navigator.storage.estimate()
    if (usage && quota) {
      const percentageUsed = ((usage / quota) * 100).toFixed(2)
      console.log({
        usageInMB: `${(usage / 1024 / 1024).toFixed(2)}MB`,
        quotaInMB: `${(quota / 1024 / 1024).toFixed(2)}MB`,
        percentageUsed: `${percentageUsed}%`,
      })
    }
  } catch (error) {
    console.error("Failed to get storage estimate:", error)
  }
}

export const logLocalStorageEstimate = (): void => {
  try {
    let totalSize = 0
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (key) {
        const value = localStorage.getItem(key) || ""
        totalSize += new Blob([value]).size
      }
    }

    // Approximate quota (varies by browser)
    const approximateQuota = 5 * 1024 * 1024
    const percentageUsed = ((totalSize / approximateQuota) * 100).toFixed(2)

    console.log({
      usageInMB: `${(totalSize / 1024 / 1024).toFixed(2)}MB`,
      approximateQuotaInMB: "5MB",
      percentageUsed: `${percentageUsed}%`,
      itemCount: localStorage.length,
    })
  } catch (error) {
    console.error("Failed to measure localStorage size:", error)
  }
}
