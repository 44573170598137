import { Slider } from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { setTokensPerConversation } from "features/insights/insightsSlice"
import { useTokenEstimates } from "features/insights/utils/hooks/useTokenEstimates"

const TokensPerConvSelection = () => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { conversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  const { numberOfConversations, largestConversationTokenCount } =
    useTokenEstimates()

  if (conversations)
    return (
      <div className="flex-row" style={{ width: "100%", gap: "1.5rem" }}>
        <div style={{ flexGrow: "1" }}>
          <div className="flex-row spaced" style={{ marginBottom: "1rem" }}>
            <label className="skapa-label scaled no-wrap">
              <span>Number of conversations:</span>
              <br /> {numberOfConversations} /{" "}
              {Object.keys(conversations).length}
            </label>
            <label
              className="skapa-label scaled no-wrap"
              style={{ textAlign: "right" }}
            >
              <span>Tokens per conversation:</span>
              <br />
              {insightsState.tokensPerConversation} /{" "}
              {largestConversationTokenCount && largestConversationTokenCount}
            </label>
          </div>
          
          <Slider
            id="amountSlider"
            value={insightsState.tokensPerConversation}
            min={100}
            max={
              largestConversationTokenCount
                ? largestConversationTokenCount
                : 4000
            }
            step={100}
            onChange={(_, newValue) => {
              if (typeof newValue === "number") {
                dispatch(setTokensPerConversation(newValue))
              }
            }}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `${value} tokens`}
          />
        </div>
      </div>
    )
}

export default TokensPerConvSelection
