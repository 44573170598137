import { useState, useEffect } from "react"
import { useSampleTexts, TIMING } from "./constants"

export const useSampleText = () => {
  const [sampleTextIndex, setSampleTextIndex] = useState(0)
  const [fadeState, setFadeState] = useState(true)
  const SAMPLE_TEXTS = useSampleTexts()

  useEffect(() => {
    const switchText = () => {
      setFadeState(false)
      setTimeout(() => {
        setSampleTextIndex((prevIndex) => (prevIndex + 1) % SAMPLE_TEXTS.length)
        setFadeState(true)
      }, TIMING.FADE_TRANSITION_MS)
    }

    const currentText = SAMPLE_TEXTS[sampleTextIndex]
    const dynamicInterval = currentText.length * TIMING.TEXT_DURATION_MULTIPLIER
    const finalInterval = Math.min(
      Math.max(dynamicInterval, TIMING.MIN_INTERVAL),
      TIMING.MAX_INTERVAL,
    )

    const interval = setInterval(switchText, finalInterval)
    return () => clearInterval(interval)
  }, [sampleTextIndex])

  return { sampleTextIndex, fadeState }
}
