import PromptInput from "features/insights/PromptInput"
import { PromptControlProps } from "features/insights/types"
import InlineMessage from "@ingka/inline-message"
import informationCircle from "@ingka/ssr-icon/paths/information-circle"
import InsightsButton from "../buttons/InsightsButton"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { displayCost } from "../../utils/costUtils/costFormatting"
import { calculateInsightCost } from "features/insights/utils/costUtils/costUtils"
import LLMModelSelectionChat from "./LLMModelSelectionChat"
import { useTranslation } from "react-i18next"

const LLMProcessing = ({ promptCrud }: PromptControlProps) => {
  const { t } = useTranslation()
  const { conversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const { selectedInsightPrompt } = useAppSelector(
    (state: RootState) => state.insights,
  )
  const promptInfo = () => {
    const cost = calculateInsightCost(
      selectedInsightPrompt,
      conversations || [],
    )

    return (
      <div className="flex-row">
        <LLMModelSelectionChat />
        {cost ? displayCost(cost) : ""}
      </div>
    )
  }

  return (
    <div className="flex-column" style={{ gap: "2rem" }}>
      <InlineMessage
        title={t("warnings:llmDataProcessing:title")}
        body={t("warnings:llmDataProcessing:body")}
        variant="cautionary"
        ssrIcon={informationCircle}
      />

      <div className="modal-section-parent">
        <div className="flex-row spaced">
          <h4>Insights prompt</h4>
          <InsightsButton />
        </div>
        <div style={{ margin: "0 0 0 0" }}>
          <label className="skapa-label">
            Define a prompt for generating insights on the sampled data
          </label>
          <PromptInput type="insight" promptInfo={promptInfo()} />
          {promptCrud && promptCrud}
        </div>
      </div>
    </div>
  )
}

export default LLMProcessing
