import { useAppDispatch } from "app/hooks"
import { setSelectedChatModel } from "../../insightsSlice"
import { LLMModel } from "../../types"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import { insightsModels } from "features/insights/insightConsts"

const LLMModelSelectionChat = () => {
  const dispatch = useAppDispatch()
  const { currentThread, inputDisabled } = useInsightsHelperFunctions()

  if (!currentThread || !currentThread.model) return
  const chatModel = currentThread.model

  return (
    <select
      id="chat-model-selection"
      name="chat-model-selection"
      value={chatModel ?? "GPT-4o Mini"}
      disabled={inputDisabled}
      onChange={(e) =>
        dispatch(setSelectedChatModel(e.target.value as LLMModel))
      }
      style={inputDisabled ? { cursor: "default" } : {}}
    >
      {insightsModels.map((model) => (
        <option key={model.name} value={model.name}>
          {model.name}
        </option>
      ))}
    </select>
  )
}

export default LLMModelSelectionChat
