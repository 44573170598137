import { IConversation } from "app/types"
import { SavedPrompt } from "../../types"
import { formatSampledConversations } from "../llmUtils/llmUtils"
import { formatLLMChat } from "../llmUtils/llmFormatting"
import { useAppSelector } from "app/hooks"
import { tokensEstimate } from "features/insights/insightConsts"
import useInsightsHelperFunctions from "../hooks/useInsightsHelperFunctions"

export function calculateInsightCost(
  selectedPrompt: SavedPrompt,
  conversations: IConversation[],
): number {
  const insightsState = useAppSelector((state) => state.insights)
  const { currentThread } = useInsightsHelperFunctions()
  const modelInfo = insightsState.insightsModelConfig.find(
    (m) => m.name === currentThread?.model,
  )

  if (!modelInfo || !modelInfo.cost || !modelInfo.cost.input) return 0

  const conversationTokens = conversations.reduce((total, conversation) => {
    const conversationText = formatSampledConversations(conversation)
    return total + Math.floor(conversationText.length * tokensEstimate)
  }, 0)

  const promptTokens = Math.floor(selectedPrompt.prompt.length * tokensEstimate)

  const totalTokens = promptTokens + conversationTokens
  return (totalTokens / 1000000) * modelInfo.cost.input
}

export function calculateLLMChatStats(selectedPrompt: SavedPrompt): {
  tokens: number | undefined
  cost: number | undefined
} {
  const insightsState = useAppSelector((state) => state.insights)
  const { currentThread } = useInsightsHelperFunctions()
  const modelInfo = insightsState.insightsModelConfig.find(
    (m) => m.name === currentThread?.model,
  )

  if (
    !currentThread ||
    !currentThread.chatMessages ||
    !currentThread.model ||
    !modelInfo ||
    !modelInfo.cost ||
    !modelInfo.cost.input
  ) {
    const tokens = undefined
    const cost = undefined
    return { tokens, cost }
  }

  const { formattedLLMChat } = formatLLMChat(currentThread.chatMessages)

  const llmChatTokens = formattedLLMChat.reduce((total, string) => {
    return total + Math.floor(string.length * tokensEstimate)
  }, 0)

  const promptTokens = Math.floor(selectedPrompt.prompt.length * tokensEstimate)

  const tokens = promptTokens + llmChatTokens
  const cost = (tokens / 1000000) * modelInfo.cost.input

  return { tokens, cost }
}
