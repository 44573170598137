import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import InsightsTable from "features/insights/InsightsTable"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import { useEffect, useState } from "react"
import { CSSTransition } from "react-transition-group"

const AdvancedViewDetails = () => {
  const { currentThread } = useInsightsHelperFunctions()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const [visibilityState, setVisibilityState] = useState<string>("collapsed") // Options: 'collapsed', 'slightlyVisible', 'visible'

  const visible = insightsState.advancedControlsSheetVisible
  const insights = currentThread?.chatMessages?.find(
    (message) => message.type === "data",
  )

  const insightsToPass =
    typeof insights?.content === "object" ? insights.content : {}

  const displayInsights = Object.keys(insightsToPass).length !== 0

  useEffect(() => {
    insightsState.insightsLoading
      ? setVisibilityState("slightlyVisible")
      : !displayInsights
        ? setVisibilityState("collapsed")
        : setVisibilityState("visible")
  }, [insightsState])

  return (
    <CSSTransition in={visible} timeout={250} classNames="slide" unmountOnExit>
      <div className={`modal-details-panel-parent ${visibilityState}`}>
        <div className="overflow-hider">
          <div className="modal-details-panel">
            <InsightsTable
              loading={insightsState.insightsLoading}
              insights={insightsToPass}
            />
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default AdvancedViewDetails
