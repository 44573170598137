import { KeyValueType } from "app/types"
import { ChatThread } from "../types"

export const currentFilterStoredThreads = (
	filters: KeyValueType[] = [],
	threads: ChatThread[] = [],
): number[] => {
	if (
		!Array.isArray(threads) ||
		!Array.isArray(filters) ||
		!threads.length ||
		!filters.length
	)
		return []

	return threads
		.map((thread, index) => {
			if (!thread?.filters) return -1
			return thread.filters === JSON.stringify(filters) ? index : -1
		})
		.filter((index) => index !== -1)
}

const getThreadTimestamp = (thread: ChatThread): number => {
	return new Date(thread.lastInteraction).getTime()
}

export const mostRecentThread = (
	indices: number[] = [],
	threads: ChatThread[] = [],
): number => {
	if (
		!Array.isArray(indices) ||
		!Array.isArray(threads) ||
		!indices.length ||
		!threads.length
	)
		return -1

	return indices.reduce((latest, currentIndex) => {
		if (!threads[latest] || !threads[currentIndex]) return latest

		return getThreadTimestamp(threads[currentIndex]) > getThreadTimestamp(threads[latest])
			? currentIndex
			: latest
	}, indices[0])
}
