import Button from "@ingka/button"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { InsightsUtilsRunners } from "../../utils/llmUtils/llmUtilRunners"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"

const SendMessageButton = () => {
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { runCancelEdit, runChatMessage } = InsightsUtilsRunners()
  const { inputDisabled } = useInsightsHelperFunctions()
  
  return (
    <>
      {insightsState.messageEditIndex !== null && (
        <Button
          disabled={insightsState.insightsSummaryLoading}
          onClick={() => runCancelEdit()}
          type="secondary"
          small={true}
        >
          Cancel edit
        </Button>
      )}

      <Button
        disabled={inputDisabled}
        onClick={() => runChatMessage()}
        type="primary"
        small={true}
      >
        Send
      </Button>
    </>
  )
}

export default SendMessageButton
