import Text from "@ingka/text"
import { Grid } from "@mui/material"
import Button from "@ingka/button"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import "./styles/header.css"
import HeaderMenu from "./HeaderMenu"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"

const Header = () => {
  const { t } = useTranslation()
  const { isAdmin } = useAppSelector((state: RootState) => state.auth)
  const { filters } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const navigate = useNavigate()

  const navigateWithParams = (path: string) => {
    const params = new URLSearchParams()
    filters.forEach(({ key, value }) => {
      params.append(key, value)
    })
    navigate(path + "?" + params.toString())
  }

  return (
    <Grid
      container
      item
      xs={12}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        background: "#003E72",
        height: "4rem",
        zIndex: 99,
        position: "fixed"
      }}
    >
      <Grid item marginLeft={2}>
        <Text tagName="h3" className="headerTitle">
          MOSAIK - VIEW
        </Text>
      </Grid>
      <Grid item style={{paddingRight: "5rem"}}>
        <Button
          type="tertiary"
          text={t("buttons:view")}
          onClick={() => navigateWithParams("/")}
          style={{ color: "#ffdb00" }}
        />
        {isAdmin && (
          <>
            <Button
              type="tertiary"
              text={t("buttons:insights")}
              onClick={() => navigate("/insights")}
              style={{ color: "#ffdb00" }}
            />
            <Button
              type="tertiary"
              text={t("buttons:admin")}
              onClick={() => navigate("/admin")}
              style={{ color: "#ffdb00" }}
            />
          </>
        )}
      </Grid>
      <Grid item display="flex" marginRight={2}>
        <HeaderMenu />
      </Grid>
    </Grid>
  )
}

export default Header
