import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { fetchSavedPrompts } from "features/insights/insightsSlice"

export const useStoredPrompts = () => {
  const { user } = useAppSelector((state: RootState) => state.auth)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (user) {
      dispatch(fetchSavedPrompts({ collectionIdentifier: user.id }))
    }
  }, [dispatch, user])
}
