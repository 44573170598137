import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { SheetViewProps } from "../../types"
import {
  setAdvancedControlsSheetVisible,
  setChatHistoryVisibility,
  setConversationsViewVisibility,
  setPreviewedThread,
} from "../../insightsSlice"
import AdvancedView from "../../components/views/AdvancedView"
import AdvancedViewDetails from "../../components/views/AdvancedViewDetails"
import HistoryView from "../../components/views/HistoryView"
import HistoryChatView from "../../components/views/HistoryChatView"
import { ReactElement } from "react"

export function useSheetViews(promptCrud: ReactElement) {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)

  const advancedView: SheetViewProps = {
    visible: insightsState.advancedControlsSheetVisible,
    handleClose: () => dispatch(setAdvancedControlsSheetVisible(false)),
    content: <AdvancedView promptCrud={promptCrud} />,
    footer: <></>,
    details: <AdvancedViewDetails />,
  }

  const historyView: SheetViewProps = {
    visible: insightsState.chatHistoryVisible,
    handleClose: () => {
      dispatch(setPreviewedThread(insightsState.selectedThread))
      dispatch(setChatHistoryVisibility(false))
    },
    content: <HistoryView />,
    footer: <></>,
    details: <HistoryChatView />,
  }

  const conversationsView: SheetViewProps = {
    visible: insightsState.conversationsViewVisible,
    handleClose: () => dispatch(setConversationsViewVisibility(false)),
    content: <AdvancedView promptCrud={promptCrud} />,
    footer: <></>,
    details: <AdvancedViewDetails />,
  }

  return {
    advancedView,
    historyView,
    conversationsView,
  }
}
