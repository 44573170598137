import { Dispatch } from "redux"
import {
  fetchConversationInsights,
  fetchInsightsSummary,
} from "../../insightsSlice"
import {
  ConversationInsight,
  SavedPrompt,
  Message,
  LLMModel,
} from "../../types"
import { formatLLMChat, formatSampledConversations } from "./llmFormatting"

export const updateInsights = async (
  prompt: SavedPrompt,
  conversations: any[],
  dispatch: Dispatch<any>,
  labelId?: number,
) => {
  if (!prompt?.prompt) return

  const maxIterations = Math.min(prompt.amount, conversations.length)
  const messages: Record<string, string> = {}

  conversations.slice(0, maxIterations).forEach((conversation) => {
    messages[conversation.id] = formatSampledConversations(conversation)
  })

  if (labelId) {
    dispatch(
      fetchConversationInsights({
        messages,
        labelId: labelId,
        promptId: prompt.id,
      }),
    )
    return
  }

  dispatch(
    fetchConversationInsights({
      messages,
      systemPrompt: prompt.prompt,
      version: prompt.model,
    }),
  )
}

export const updateInsightsSummary = (
  prompt: SavedPrompt,
  model: LLMModel,
  insights: Record<string, ConversationInsight>,
  dispatch: Dispatch<any>,
  labelId?: number,
) => {
  if (!prompt?.prompt) return
  const insightsValues = Object.values(insights).map(
    (i: any) => "<" + i.insights + ">",
  )

  if (labelId) {
    dispatch(
      fetchInsightsSummary({
        insights: insightsValues,
        labelId: labelId,
        promptId: prompt.id,
      }),
    )
    return
  }
  dispatch(
    fetchInsightsSummary({
      insights: insightsValues,
      version: model,
      systemPrompt: prompt.prompt,
    }),
  )
}

export const updateLLMChat = (
  insights: Message[],
  model: LLMModel,
  dispatch: Dispatch<any>,
) => {
  const { config, formattedLLMChat } = formatLLMChat(insights)

  // For logging purposes
  console.log(formattedLLMChat)

  dispatch(
    fetchInsightsSummary({
      insights: formattedLLMChat,
      version: model,
      systemPrompt: config.systemPrompt,
    }),
  )
}
export { formatSampledConversations }
