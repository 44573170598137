import { useState } from "react"
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Menu,
  TextField,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { PromptType, SavedPrompt } from "./types"
import { setCurrentMessage, setSelectedPrompt } from "./insightsSlice"
import Add from "@mui/icons-material/Add"
import Delete from "@mui/icons-material/Delete"

interface PromptCrudProps {
  prompts?: SavedPrompt[]
  promptType: PromptType
  promptAction: JSX.Element
  onPromptSave: (selectedPrompt: SavedPrompt, promptType: PromptType) => void
  onAddPrompt: (prompt: SavedPrompt, promptType: PromptType) => void
  onPromptDelete: (promptId: string, promptType: PromptType) => void
}

const PromptCrud: React.FC<PromptCrudProps> = ({
  prompts,
  promptType,
  onPromptSave,
  onAddPrompt,
  onPromptDelete,
}) => {
  const dispatch = useAppDispatch()
  const { isSaving } = useAppSelector((state: RootState) => state.insights)
  const selectedPrompt = useAppSelector((state: RootState) =>
    promptType === "insight"
      ? state.insights.selectedInsightPrompt
      : state.insights.selectedSummaryPrompt,
  )
  const isSaved = useAppSelector((state: RootState) =>
    promptType === "insight"
      ? state.insights.insightPromptIsSaved
      : state.insights.summaryPromptIsSaved,
  )

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [formOpen, setFormOpen] = useState(false)
  const [displayName, setDisplayName] = useState("")
  const [saveAsNewOpen, setSaveAsNewOpen] = useState(false)
  const [saveAsNewName, setSaveAsNewName] = useState("")

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const promptSelected = (prompt: SavedPrompt) => {
    if (promptType === "chat") {
      dispatch(setCurrentMessage(prompt.prompt))
      dispatch(setSelectedPrompt({ prompt, promptType: "summary" }))
    } else {
      dispatch(setSelectedPrompt({ prompt, promptType }))
    }
    handleMenuClose()
  }

  const addPrompt = () => {
    if (!displayName) return

    // Summary prompt functionality is also used for chat
    const type = promptType === "chat" ? "summary" : promptType

    onAddPrompt(
      {
        displayName,
        prompt: "",
        id: "",
        amount: 20,
        model: "GPT-4o Mini",
      },
      type,
    )
    setFormOpen(false)
    setDisplayName("")
  }

  const removePrompt = (promptId: string) => {
    onPromptDelete(promptId, promptType)
  }

  const savePrompt = () => {
    promptType === "chat"
      ? onPromptSave(selectedPrompt, "summary")
      : onPromptSave(selectedPrompt, promptType)
  }

  const saveAsNewPrompt = () => {
    if (!saveAsNewName) return

    const promptBody: SavedPrompt = {
      ...selectedPrompt,
      displayName: saveAsNewName,
      id: "",
    }
    promptType === "chat"
      ? onAddPrompt(promptBody, "summary")
      : onAddPrompt(promptBody, promptType)
    setSaveAsNewOpen(false)
    setSaveAsNewName("")
  }

  // Little hack so we don't have to change the current way of handling saved prompts
  const emptyPrompt: SavedPrompt = {
    displayName: "Browse prompts",
    id: "723aeeeb-6b2f-471d-b4ee-ff7c2a12c2a0",
    prompt: "",
    model: "GPT-4o Mini",
    amount: 200,
  }

  const isInitialPrompt =
    selectedPrompt.id === "723aeeeb-6b2f-471d-b4ee-ff7c2a12c2a0"

  return (
    <>
      <div>
        <div className="flex-row">
          <Button
            onClick={handleMenuOpen}
            variant="contained"
            className="selected-prompt"
          >
            {selectedPrompt.displayName}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <Box width={400} padding={1}>
              <Box display="flex" alignItems="center" mb={1}>
                <IconButton onClick={() => setFormOpen(!formOpen)}>
                  <Add />
                </IconButton>
                {formOpen && (
                  <>
                    <TextField
                      size="small"
                      value={displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                      placeholder="Enter name"
                      inputProps={{ maxLength: 50 }}
                    />
                    <Button
                      onClick={addPrompt}
                      disabled={prompts && prompts.length >= 10}
                    >
                      Add
                    </Button>
                  </>
                )}
              </Box>
              <List>
                <ListItem
                  key={"723aeeeb-6b2f-471d-b4ee-ff7c2a12c2a0"}
                  onClick={() => promptSelected(emptyPrompt)}
                  selected={
                    selectedPrompt.id === "723aeeeb-6b2f-471d-b4ee-ff7c2a12c2a0"
                  }
                >
                  <ListItemButton>None</ListItemButton>
                </ListItem>
                {prompts?.map((prompt) => (
                  <ListItem
                    key={prompt.id}
                    secondaryAction={
                      <IconButton
                        disabled={isSaving}
                        edge="end"
                        onClick={() => removePrompt(prompt.id)}
                      >
                        <Delete />
                      </IconButton>
                    }
                    onClick={() => {
                      setSaveAsNewOpen(false)
                      promptSelected(prompt)
                    }}
                    selected={selectedPrompt.id === prompt.id}
                  >
                    <ListItemButton>{prompt.displayName}</ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Menu>
          {!isInitialPrompt && !saveAsNewOpen && (
            <Button disabled={isSaving || isSaved} onClick={savePrompt}>
              Update prompt
            </Button>
          )}
          {!saveAsNewOpen && (
            <Button
              disabled={isSaving || isSaved}
              onClick={() => setSaveAsNewOpen(true)}
            >
              Save as new Prompt
            </Button>
          )}
          {saveAsNewOpen && (
            <>
              <div
                style={{
                  transform: "scale(.8125)",
                  transformOrigin: "center left",
                  margin: "-.25rem -2.5rem -.25rem 0",
                }}
              >
                <TextField
                  size="small"
                  value={saveAsNewName}
                  onChange={(e) => setSaveAsNewName(e.target.value)}
                  placeholder="Enter name"
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              <Button onClick={() => setSaveAsNewOpen(false)}>Cancel</Button>
              <Button
                onClick={saveAsNewPrompt}
                disabled={prompts && prompts.length >= 10}
              >
                Add
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default PromptCrud
