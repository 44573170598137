import { ChatThread, Message } from "../types"
import { StorageKeys } from "app/types"
import { logLocalStorageEstimate } from "./storageLoggingUtils"

// Thread validation helpers
const isEmptyThread = (thread: ChatThread): boolean => {
  return (
    thread.chatMessages.length === 1 &&
    (thread.chatMessages[0].type === "default-sample" ||
      thread.chatMessages[0].type === "data")
  )
}

const isValidMessage = (msg: any): msg is Message => {
  return (
    msg &&
    typeof msg === "object" &&
    (typeof msg.content === "string" || typeof msg.content === "object") &&
    typeof msg.type === "string"
  )
}

const isValidChatThread = (thread: any): thread is ChatThread => {
  if (!thread || typeof thread !== "object") return false

  return (
    Array.isArray(thread.chatMessages) &&
    typeof thread.uuid === "string" &&
    typeof thread.filters === "string" &&
    typeof thread.lastInteraction === "number" &&
    thread.chatMessages.every(isValidMessage)
  )
}

const isValidChatThreadArray = (threads: any): threads is ChatThread[] => {
  return Array.isArray(threads) && threads.every(isValidChatThread)
}

// Storage operations
const writeToStorage = (key: StorageKeys, data: unknown): boolean => {
  try {
    localStorage.setItem(key, JSON.stringify(data))
    return true
  } catch (e) {
    console.error(`Failed to write to storage for key ${key}:`, e)
    return false
  }
}

const readFromStorage = <T>(key: StorageKeys): T | null => {
  try {
    const stored = localStorage.getItem(key)
    return stored ? JSON.parse(stored) : null
  } catch (e) {
    console.error(`Failed to read from storage for key ${key}:`, e)
    return null
  }
}

// Thread cleanup helpers
const removeEmptyThreads = (
  threads: ChatThread[],
  currentThreadId: string | null,
): ChatThread[] => {
  return threads.filter(
    (thread) => !isEmptyThread(thread) || thread.uuid === currentThreadId,
  )
}

// Exported functions
export const saveChatThreads = (threads: ChatThread[]): boolean => {
  logLocalStorageEstimate()

  if (!isValidChatThreadArray(threads)) {
    console.error("Attempted to save invalid chat threads")
  }

  const currentThreadId = readFromStorage<string>(StorageKeys.SelectedThread)
  const cleanThreads = removeEmptyThreads(threads, currentThreadId)

  try {
    writeToStorage(StorageKeys.ChatThreads, cleanThreads)
    return true
  } catch (e) {
    if (e instanceof DOMException && e.name === "QuotaExceededError")
      return false
    throw e
  }
}

export const loadChatThreads = (): ChatThread[] => {
  const threads = readFromStorage<ChatThread[]>(StorageKeys.ChatThreads)

  if (!threads) return []
  if (!isValidChatThreadArray(threads)) {
    console.error("Found corrupted chat threads in storage")
    return []
  }

  return threads
}

export const saveSelectedThread = (threadId: string): void => {
  writeToStorage(StorageKeys.SelectedThread, threadId)
}

export const loadSelectedThread = (): string => {
  return readFromStorage<string>(StorageKeys.SelectedThread) || ""
}
