import { useAppDispatch } from "app/hooks"
import {
  removeThread,
  setPreviewedThread,
} from "features/insights/insightsSlice"
import Button from "@ingka/button"
import { useTranslation } from "react-i18next"

interface RemoveChatButtonProps {
  thread: string
}

const RemoveChatButton = ({ thread }: RemoveChatButtonProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const removeThreadAction = () => {
    const userConfirmed = window.confirm(t("confirmations:removeThread"))
    if (!userConfirmed) return
    dispatch(setPreviewedThread(""))
    dispatch(removeThread(thread))
  }

  return (
    <div className="flex-row">
      <Button type="secondary" small={true} onClick={removeThreadAction}>
        Delete thread
      </Button>
      {/* <Button
        type="primary"
        small={true}
        disabled={true}
        onClick={removeThreadAction}
      >
        Select thread
      </Button> */}
    </div>
  )
}

export default RemoveChatButton
