import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import InsightsMessage from "features/insights/InsightsMessage"
import ViewInsightsButton from "../../buttons/ViewInsightsButton"
import { ChatViewProps } from "features/insights/types"
import { styles } from "./styles"
import NoMessages from "./NoMessages"
import { MessageToolbar } from "./MessageToolbar"
import { useSampleText } from "./useSampleText"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"

const ChatView = ({
  chatControlsRef,
  hasAppliedInitialData,
}: ChatViewProps) => {
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { currentThread } = useInsightsHelperFunctions()
  const { sampleTextIndex, fadeState } = useSampleText()

  const clientHeight = chatControlsRef.current?.clientHeight ?? null

  const isMessageInEditedBranch = (messageIndex: number): boolean => {
    return Boolean(
      insightsState.messageEditIndex &&
        messageIndex > insightsState.messageEditIndex,
    )
  }

  const toolbarDisabled = (messageIndex: number): boolean => {
    return Boolean(
      insightsState.insightsSummaryLoading ||
        (insightsState.messageEditIndex &&
          insightsState.messageEditIndex < messageIndex),
    )
  }

  const showNoMessagesContent =
    !currentThread ||
    currentThread.chatMessages.length === 0 ||
    (currentThread.chatMessages.length === 1 &&
      currentThread.chatMessages[0]?.type === "default-sample")

  return (
    <div className="chat" style={styles.llmChatPadding(clientHeight)}>
      {showNoMessagesContent ? (
        <NoMessages
          clientHeight={clientHeight}
          sampleTextIndex={sampleTextIndex}
          fadeState={fadeState}
        />
      ) : (
        currentThread.chatMessages.map((message, messageIndex) => (
          <InsightsMessage
            key={messageIndex}
            messageContent={message.content}
            messageEditState={isMessageInEditedBranch(messageIndex)}
            type={message.type}
            toolbar={
              <MessageToolbar
                message={message}
                messageIndex={messageIndex}
                isDisabled={toolbarDisabled(messageIndex)}
                hasAppliedInitialData={hasAppliedInitialData}
              />
            }
            button={<ViewInsightsButton />}
          />
        ))
      )}
    </div>
  )
}

export default ChatView
