import { saveAs } from "file-saver"
import { styles } from "./styles"
import SentMessageToolbar from "../../toolbars/SentMessageToolbar"
import ReceivedMessageToolbar from "../../toolbars/ReceivedMessageToolbar"
import DefaultDataToolbar from "../../toolbars/DefaultDataToolbar"
import InsertedDataToolbar from "../../toolbars/InsertedDataToolbar"
import { Message } from "features/insights/types"

interface MessageToolbarProps {
  message: Message
  messageIndex: number
  isDisabled: boolean
  hasAppliedInitialData: React.MutableRefObject<boolean>
}

const downloadMarkdown = (messageContent: string): void => {
  const blob = new Blob([messageContent], { type: "text/markdown" })
  saveAs(blob, "insights.md")
}

export const MessageToolbar: React.FC<MessageToolbarProps> = ({
  message,
  messageIndex,
  isDisabled,
  hasAppliedInitialData,
}) => {
  switch (message.type) {
    case "sent":
      return (
        <SentMessageToolbar messageIndex={messageIndex} disabled={isDisabled} />
      )
    case "received":
      return (
        <>
          <ReceivedMessageToolbar
            messageIndex={messageIndex}
            disabled={isDisabled}
          />
          <div className="flex-row message-toolbar" style={styles.exportButton}>
            <button onClick={() => downloadMarkdown(message.content)}>
              Export insights
            </button>
          </div>
        </>
      )
    case "data":
      return (
        <InsertedDataToolbar
          disabled={isDisabled}
          hasAppliedInitialData={hasAppliedInitialData}
        />
      )
    case "default-sample":
      return <DefaultDataToolbar disabled={isDisabled} />
    default:
      return null
  }
}
