import { LLMFormattingConfig } from "../../types"

export const defaultConfig: LLMFormattingConfig = {
  version: "1.0.0",
  instructions: {
    received: `<This is you, the LLM model, one of your previously given answers to me>: <${JSON.stringify("PLACEHOLDER")}>`,
    sent: {
      latest: `<This is the latest instruction sent from me, the user to be focused on in your answer>: <${JSON.stringify("PLACEHOLDER")}>`,
      previous: `<This is a previous instruction sent from me, the user>: <${JSON.stringify("PLACEHOLDER")}>`,
    },
    data: `<This is a list of insights extracted from a sample of support conversations from our system>: <${JSON.stringify("PLACEHOLDER")}>`,
  },
  systemPrompt:
    "GET STRAIGHT TO THE POINT, AVOID STARTING WITH ANSWERS LIKE *Based on the sample of support conversations from your system...* GET STRAIGHT TO THE POINT INSTEAD",
}
