import { Grid } from "@mui/material"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import InfoCard from "components/InfoCard"
import { useRef } from "react"
import ChatView from "./components/views/chat/ChatView"
import ChatViewOverlay from "./components/misc/ChatViewOverlay"
import SheetView from "./components/views/SheetView"
import { useConversationThreads } from "./utils/hooks/useConversationThreads"
import { useSheetViews } from "./utils/hooks/useSheetViews"
import { usePromptCruds } from "./utils/hooks/usePromptCruds"
import { useStoredPrompts } from "features/listConversations/hooks/useStoredPrompts"

const ConversationInsights = () => {
  // Selectors
  const { conversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  // Refs
  const chatControlsRef = useRef<HTMLDivElement | null>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  // Custom hooks
  const { hasAppliedInitialData } = useConversationThreads()
  const { prePromptCrud, summaryPromptCrud } = usePromptCruds()
  const { advancedView, historyView, conversationsView } =
    useSheetViews(prePromptCrud)
  useStoredPrompts()

  const insightsPageDisabled = !conversations || conversations.length === 0

  if (insightsPageDisabled) {
    return (
      <Grid container justifyContent="center" alignItems="center" height="90vh">
        <InfoCard
          text="You have no conversations to get insights on."
          header="No conversations"
        />
      </Grid>
    )
  }

  return (
    <>
      <SheetView {...historyView} />
      <SheetView {...advancedView} />
      <SheetView {...conversationsView} />

      <ChatViewOverlay
        chatControlsRef={chatControlsRef}
        textareaRef={textareaRef}
        promptCrud={summaryPromptCrud}
        hasAppliedInitialData={hasAppliedInitialData}
      />

      <ChatView
        chatControlsRef={chatControlsRef}
        hasAppliedInitialData={hasAppliedInitialData}
      />
    </>
  )
}

export default ConversationInsights
