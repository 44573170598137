import { IConversation } from "app/types"
import { formatSampledConversations } from "./llmUtils/llmFormatting"
import { tokensEstimate } from "../insightConsts"

interface DefaultDataReturns {
  assembledConversations: string
  numberOfConversations: number
  largestConversationTokenCount: number
  totalTokenCount: number
}

export const defaultData = (
  conversations: IConversation[],
  tokensPerConversation: number,
  tokenLimit: number,
): DefaultDataReturns => {
  let assembledConversations = ""
  let numberOfConversations = 0
  let largestConversationTokenCount = 0

  for (let i = 0; i < conversations.length; i++) {
    const conversation = conversations[i]
    let formattedConversation = formatSampledConversations(conversation)

    // Token count of the unsliced raw version of conversation
    const unslicedTokenCount = formattedConversation.length * tokensEstimate

    if (largestConversationTokenCount < unslicedTokenCount)
      largestConversationTokenCount = Math.floor(unslicedTokenCount)

    const maxLength = Math.min(
      Math.floor(tokensPerConversation / tokensEstimate),
      formattedConversation.length,
    )

    // Conversations are sliced using the limit set for tokens per conversation
    let sliceLength = maxLength
    while (sliceLength > 0 && formattedConversation[sliceLength] !== " ") {
      sliceLength--
    }
    if (sliceLength === 0) sliceLength = maxLength

    const slicedConversation = formattedConversation.slice(0, sliceLength)

    // Check if adding this conversation exceeds the total token limit set for the insights data
    const estimatedLength =
      (assembledConversations.length + slicedConversation.length) *
      tokensEstimate

    if (tokenLimit && estimatedLength > tokenLimit) {
      break
    }

    assembledConversations += slicedConversation
    numberOfConversations = i + 1
  }

  const totalTokenCount = Math.floor(
    assembledConversations.length * tokensEstimate,
  )

  return {
    assembledConversations,
    numberOfConversations,
    largestConversationTokenCount,
    totalTokenCount,
  }
}
