import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import FeatureTitle from "components/FeatureTitle"
import ChatThreadPreview from "../misc/ChatThreadPreview"
import { ChatThread } from "features/insights/types"
import { loadChatThreads } from "features/insights/utils/storageUtils"
import { useEffect, useState } from "react"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"

const HistoryView = () => {
  const [safeThreads, setSafeThreads] = useState<ChatThread[]>([])
  const { currentThread } = useInsightsHelperFunctions()
  const insightsState = useAppSelector((state: RootState) => state.insights)

  useEffect(() => {
    const threads = loadChatThreads()
    setSafeThreads(threads)
  }, [insightsState.chatThreads, currentThread?.uuid])

  const chatHistoryRenderCondition = (thread: ChatThread) => {
    try {
      const isDefaultSampleOnly =
        thread.chatMessages.length === 1 &&
        (thread.chatMessages[0].type === "default-sample" ||
          thread.chatMessages[0].type === "data")

      return thread.chatMessages.length >= 1 && !isDefaultSampleOnly
    } catch (e) {
      console.error("Invalid thread structure:", e)
      return false
    }
  }

  const sortByLatestInteraction = (a: ChatThread, b: ChatThread) => {
    try {
      return (
        new Date(b.lastInteraction).getTime() -
        new Date(a.lastInteraction).getTime()
      )
    } catch (e) {
      console.error("Error sorting threads:", e)
      return 0
    }
  }

  const renderChatHistory = () => {
    if (!safeThreads.length) return

    const validThreads = safeThreads.filter(chatHistoryRenderCondition)
    if (validThreads.length === 0) return

    return validThreads
      .sort((a, b) => {
        if (a.uuid === currentThread?.uuid) return -1
        if (b.uuid === currentThread?.uuid) return 1
        return sortByLatestInteraction(a, b)
      })
      .map((thread, index) => (
        <ChatThreadPreview
          key={thread.uuid}
          thread={thread}
          index={index}
          currentChat={thread.uuid === currentThread?.uuid}
        />
      ))
  }

  return (
    <div style={{ position: "relative", height: "inherit" }}>
      <div className="controls-parent">
        <div style={{ marginBottom: "1rem" }}>
          <FeatureTitle featureName="Chat history" />
        </div>
        {renderChatHistory()}
      </div>
    </div>
  )
}

export default HistoryView
