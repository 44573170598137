import { InsightModel } from "./types"

/* Estimation for string length -> number of tokens.
   Uses the two most common constants for estimating the number of tokens given a string length (e^-1 and 1/4)
   and averages them. **/
export const tokensEstimate = (1 / Math.E + 1 / 4) / 2

export const insightsModels: InsightModel[] = [
  {
    name: "GPT-4o",
    cost: {
      input: 2.5,
      inputCached: 1.25,
      output: 10,
    },
    contextWindow: 128000,
  },
  {
    name: "GPT-4o Mini",
    cost: {
      input: 0.15,
      inputCached: 0.075,
      output: 0.6,
    },
    contextWindow: 128000,
  },
  // {
  //   name: "Gemini 1.5 Pro",
  //   cost: {
  //     input: 1.25,
  //     inputCached: 0.3125,
  //     output: 5,
  //   },
  //   contextWindow: 128000,
  //   largeContextWindow: 2000000,
  // },
]
