import Text from "@ingka/text"

const FiltersHeader: React.FC<{ title: string; summary: string }> = ({
  title,
  summary,
}) => (
  <div>
    <Text tagName="h1" style={{ fontSize: "24px", margin: "2.25rem 0 0 0" }}>
      {title}
    </Text>
    <Text tagName="p" className="color-darkGrey" style={{ margin: "0" }}>
      {summary}
    </Text>
  </div>
)

export default FiltersHeader
