import { IConversation } from "app/types"
import { LLMFormattingConfig, Message } from "../../types"
import { defaultConfig } from "./llmFormattingConfig"

export const formatSampledConversations = (conversation: IConversation) => {
  let formattedText = ""

  for (const mess of conversation.messages) {
    formattedText += `<${mess.userType}>: <${mess.text ?? mess.textRaw}> \n`
  }

  if (conversation.csatFeedback) {
    formattedText += `<customer feedback>: <${conversation.csatFeedback.feedback}> \n`
  }

  return formattedText
}

export const formatLLMChat = (insights: Message[]) => {
  const configKey = "llmChatConfig"
  const storedConfig = localStorage.getItem(configKey)

  let config: LLMFormattingConfig

  if (storedConfig) {
    const parsedConfig: LLMFormattingConfig = JSON.parse(storedConfig)
    if (parsedConfig.version === defaultConfig.version) {
      config = parsedConfig
    } else {
      console.warn(
        "Configuration version mismatch LLM instructions. Resetting to default.",
      )
      localStorage.removeItem(configKey)
      config = defaultConfig
      localStorage.setItem(configKey, JSON.stringify(defaultConfig))
    }
  } else {
    config = defaultConfig
    localStorage.setItem(configKey, JSON.stringify(defaultConfig))
  }

  const formattedLLMChat = Object.values(insights).map((i, index, array) => {
    if (i.type === "received") {
      return config.instructions.received.replace(
        JSON.stringify("PLACEHOLDER"),
        JSON.stringify(i),
      )
    } else if (i.type === "sent") {
      if (index === array.length - 1) {
        return config.instructions.sent.latest.replace(
          JSON.stringify("PLACEHOLDER"),
          JSON.stringify(i),
        )
      }
      return config.instructions.sent.previous.replace(
        JSON.stringify("PLACEHOLDER"),
        JSON.stringify(i),
      )
    } else if (i.type === "data" || i.type === "default-sample") {
      return config.instructions.data.replace(
        JSON.stringify("PLACEHOLDER"),
        JSON.stringify(i),
      )
    } else {
      return ""
    }
  })

  return { config, formattedLLMChat }
}
