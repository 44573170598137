import Grid from "@mui/material/Grid"
import AdminSettings from "features/admin/AdminSettings"
import Login from "features/auth/Login"
import ListConversations from "features/listConversations/ListConversations"
import { Navigate, Route, Routes } from "react-router-dom"
import AdminRoute from "./AdminRoute"
import PrivateRoute from "./PrivateRoute"
import ConversationInsights from "features/insights/ConversationInsights"

export const routes = (
  <Routes>
    <Route
      path="/"
      element={
        <PrivateRoute>
          <ListConversations />
        </PrivateRoute>
      }
    />
    <Route
      path="/insights"
      element={
        <AdminRoute>
          <ConversationInsights />
        </AdminRoute>
      }
    />
    <Route
      path="/admin"
      element={
        <AdminRoute>
          <AdminSettings />
        </AdminRoute>
      }
    />
    <Route
      path="/login"
      element={
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Login />
        </Grid>
      }
    />
    {/* default redirect to home page */}
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
)

export default PrivateRoute
