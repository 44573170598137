export function displayCost(cost: number) {
  const formattedCost = `$${cost.toFixed(2)}`

  const highCostColor = "rgb(174, 19, 3)"
  const mediumCostColor = "rgb(174, 148, 3)"
  const lowCostColor = "rgb(7, 140, 28)"

  const costColor =
    cost > 0.5
      ? highCostColor
      : cost > 0.1 && cost <= 0.5
        ? mediumCostColor
        : lowCostColor

  return cost ? (
    <p className="info-text">
      Estimated cost of prompt:{" "}
      <span style={{ fontWeight: "600", color: costColor }}>
        {formattedCost}
      </span>
    </p>
  ) : (
    ""
  )
}
