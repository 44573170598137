import "@ingka/table/dist/style.css"
import { Container, Grid } from "@mui/material"
import AdminUserTable from "./AdminUserTable"

const AdminSettings = () => (
  <Container
    maxWidth="xl"
    style={{ alignSelf: "flex-start", marginTop: "2rem" }}
  >
    <Grid container>
      <Grid item xs={12}>
        <AdminUserTable />
      </Grid>
    </Grid>
  </Container>
)

export default AdminSettings
