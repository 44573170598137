import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import {
  appendMessage,
  createThread,
  popMessages,
  resetInsights,
  resetMessages,
  setCurrentMessage,
  setMessageEditIndex,
  setSelectedPrompt,
} from "../../insightsSlice"
import {
  updateInsights,
  updateInsightsSummary,
  updateLLMChat,
} from "./llmUtils"
import { ChatThread, Message, SavedPrompt } from "../../types"
import { v4 as uuidv4 } from "uuid"
import { defaultData } from "../defaultData"
import useInsightsHelperFunctions from "../hooks/useInsightsHelperFunctions"
import { useTranslation } from "react-i18next"

const emptyPrompt: SavedPrompt = {
  displayName: "Browse prompts",
  id: "723aeeeb-6b2f-471d-b4ee-ff7c2a12c2a0",
  prompt: "",
  model: "GPT-4o Mini",
  amount: 200,
}

export const InsightsUtilsRunners = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { currentThread, contextWindow } = useInsightsHelperFunctions()
  const { conversations, filters } = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  const runInsights = async () => {
    if (
      !insightsState.selectedInsightPrompt.prompt ||
      !conversations ||
      !currentThread
    )
      return

    const nonEmptyChatThread =
      Object.keys(currentThread.chatMessages).length >= 2

    if (nonEmptyChatThread) {
      const userConfirmed = window.confirm(t("confirmations:llmDataProcessing"))
      if (!userConfirmed) return
    }

    runCancelEdit()
    dispatch(resetMessages())
    updateInsights(insightsState.selectedInsightPrompt, conversations, dispatch)
  }

  const runSummary = () => {
    if (!insightsState.selectedSummaryPrompt.prompt) return

    updateInsightsSummary(
      insightsState.selectedSummaryPrompt,
      insightsState.selectedChatModel,
      insightsState.insights,
      dispatch,
    )
  }

  const runChatCreate = (useConversationTokensLimit: boolean = false) => {
    if (!conversations || !contextWindow) return false

    const tokensPerConversation = useConversationTokensLimit
      ? insightsState.tokensPerConversation
      : 500

    const tokenLimit = contextWindow * (insightsState.insightsDataShare / 100)

    const { assembledConversations } = defaultData(
      conversations,
      tokensPerConversation,
      tokenLimit,
    )

    const sampleMessage: Message = {
      content: assembledConversations,
      type: "default-sample",
    }

    const newThread: ChatThread = {
      chatMessages: [sampleMessage],
      insights: {},
      filters: JSON.stringify(filters),
      recap: "",
      uuid: uuidv4(),
      lastInteraction: Date.now(),
      model: insightsState.selectedChatModel,
      tokensPerConversation: tokensPerConversation,
    }

    dispatch(resetInsights())
    dispatch(createThread(newThread))

    return true
  }

  const runChatCreateWithPrompt = (
    useConversationTokensLimit: boolean = false,
  ) => {
    const userConfirmed = window.confirm(t("confirmations:newChat"))

    if (userConfirmed) runChatCreate(useConversationTokensLimit)
    return userConfirmed
  }

  const runChatMessage = async () => {
    if (!insightsState.currentChatMessage.prompt || !currentThread) return

    const sentMessage: Message = {
      content: insightsState.currentChatMessage.prompt,
      type: "sent",
    }

    insightsState.messageEditIndex
      ? runChatEdit(sentMessage)
      : updateLLMChat(
          [...currentThread.chatMessages, sentMessage],
          insightsState.selectedChatModel,
          dispatch,
        )

    dispatch(setCurrentMessage(""))
    dispatch(appendMessage(sentMessage))
    runCancelEdit()
  }

  const runChatEdit = (latestMessage: Message) => {
    if (!insightsState.messageEditIndex || !currentThread) return

    const messagesToPost = [
      ...currentThread.chatMessages.slice(0, insightsState.messageEditIndex),
      latestMessage,
    ]

    updateLLMChat(messagesToPost, insightsState.selectedChatModel, dispatch)
    dispatch(popMessages(insightsState.messageEditIndex))
  }

  const runEditMessage = (messageIndex: number) => {
    if (!currentThread) return

    dispatch(setSelectedPrompt({ prompt: emptyPrompt, promptType: "summary" }))
    dispatch(
      setCurrentMessage(currentThread.chatMessages[messageIndex].content),
    )
    dispatch(setMessageEditIndex(messageIndex))
  }

  const runCancelEdit = () => {
    dispatch(setCurrentMessage(""))
    dispatch(setMessageEditIndex(null))
  }

  return {
    runInsights,
    runSummary,
    runChatCreate,
    runChatCreateWithPrompt,
    runChatMessage,
    runEditMessage,
    runCancelEdit,
  }
}
