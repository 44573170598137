import { useTranslation } from "react-i18next"

export const useSampleTexts = () => {
  const { t } = useTranslation()
  const sampleTextsChat = t("help:sampleTextsChat", {
    returnObjects: true,
  }) as string[]

  const SAMPLE_TEXTS = sampleTextsChat.map((text) => {
    return text
  })

  return SAMPLE_TEXTS
}

export const TIMING = {
  MIN_INTERVAL: 3000,
  MAX_INTERVAL: 10000,
  TEXT_DURATION_MULTIPLIER: 75,
  FADE_TRANSITION_MS: 500,
} as const
