import {
  ChatTextAreaProps,
  ChatViewProps,
  PromptControlProps,
} from "features/insights/types"
import SendMessageButton from "../buttons/SendMessageButton"
import ShowAdvancedButton from "../buttons/ShowAdvancedButton"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { LinearProgress } from "@mui/material"
import HistoryButton from "../buttons/HistoryButton"
import ChatTextArea from "./ChatTextArea"
import NewChatButton from "../buttons/NewChatButton"
import { displayCost } from "features/insights/utils/costUtils/costFormatting"
import { calculateLLMChatStats } from "features/insights/utils/costUtils/costUtils"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import LLMModelSelectionChat from "../input/LLMModelSelectionChat"
import FilterButton from "../buttons/FilterButton"

interface ChatViewOverlayProps
  extends ChatViewProps,
    ChatTextAreaProps,
    PromptControlProps {}

const ChatViewOverlay = ({
  chatControlsRef,
  textareaRef,
  promptCrud,
}: ChatViewOverlayProps) => {
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { inputDisabled, contextWindow } = useInsightsHelperFunctions()
  const { tokens, cost } = calculateLLMChatStats(
    insightsState.selectedSummaryPrompt,
  )

  const formattedCost = cost ? displayCost(cost) : ""

  return (
    <div className="sticky-overlay">
      <div className="chat-controls-parent">
        <div
          style={{
            width: "calc(128rem / 16)",
            pointerEvents: "all",
          }}
        >
          <HistoryButton />
        </div>

        <div className="chat-input-section">
          <div className="chat-controls" ref={chatControlsRef}>
            {inputDisabled ? (
              <div className="chat-input-loading">
                <LinearProgress color="secondary" />
              </div>
            ) : (
              <div className="flex-row spaced">
                <div className="chat-input-parent">
                  <ChatTextArea textareaRef={textareaRef} />
                </div>
              </div>
            )}
            <div
              className="flex-row spaced"
              style={{
                padding: "0 1rem",
                width: "calc(100% - 2rem)",
              }}
            >
              <div>{promptCrud}</div>
              <div>
                <div className="flex-row">
                  <ShowAdvancedButton />
                  <FilterButton navigateToRoot={true} />
                  <SendMessageButton />
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex-row llm-chat-input"
            style={{ justifyContent: "space-between" }}
          >
            <div
              className="flex-row"
              style={inputDisabled ? { opacity: ".5" } : {}}
            >
              <LLMModelSelectionChat />
              {formattedCost}
            </div>

            {tokens && contextWindow ? (
              <p className="info-text" style={{ alignSelf: "flex-end" }}>
                {Math.round((tokens / contextWindow) * 100)}% of context window
                used
              </p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          style={{
            width: "calc(128rem / 16)",
            pointerEvents: "all",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <NewChatButton />
        </div>
      </div>
    </div>
  )
}

export default ChatViewOverlay
