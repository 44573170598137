import SearchIcon from "@mui/icons-material/Search"
import { IconButton, InputBase, Paper } from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { KeyValueType } from "app/types"
import { fetchConversations } from "features/listConversations/listConversationSlice"
import { purify } from "utils/sanitize"
import { setSearchText } from "./conversationSearchSlice"

const maxLength = 60

const ConversationSearch = () => {
  const dispatch = useAppDispatch()
  const { loadingConversations, filters } = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  const { searchText } = useAppSelector(
    (state: RootState) => state.conversationSearch,
  )

  function onSearch() {
    const search: KeyValueType = {
      key: "text_contains",
      value: purify(searchText),
    }
    const filtersWithSearch = [...filters, search]

    dispatch(fetchConversations(filtersWithSearch))
  }

  function searchChanged(e: any) {
    if (e.target.value.length > maxLength) return
    dispatch(setSearchText(e.target.value))
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      event.preventDefault()
      onSearch()
    }
  }

  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "1.5rem",
        overflow: "hidden",
      }}
    >
      <IconButton sx={{ p: "10px" }} aria-label="menu" onClick={onSearch}>
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        inputProps={{ "aria-label": "search google maps" }}
        onPaste={(event) => {
          event.preventDefault()
          let pastedText = event.clipboardData.getData("text/plain")
          let fullText = searchText + pastedText
          if (fullText.length > maxLength) {
            fullText = fullText.substring(0, maxLength)
          }
          dispatch(setSearchText(fullText))
        }}
        disabled={loadingConversations}
        value={searchText}
        onChange={searchChanged}
        id="search"
        placeholder="Search"
        onKeyDown={handleKeyDown}
      />
    </Paper>
  )
}

export default ConversationSearch
