import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { InsightsUtilsRunners } from "../llmUtils/llmUtilRunners"
import { setAdvancedControlsSheetVisible } from "../../insightsSlice"
import { defaultData } from "../defaultData"
import useInsightsHelperFunctions from "./useInsightsHelperFunctions"

export const useTokenEstimates = () => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { runChatCreateWithPrompt } = InsightsUtilsRunners()
  const { contextWindow } = useInsightsHelperFunctions()
  const { conversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  const {
    numberOfConversations,
    largestConversationTokenCount,
    totalTokenCount,
  } = defaultData(
    conversations || [],
    insightsState.tokensPerConversation,
    contextWindow ? contextWindow * (insightsState.insightsDataShare / 100) : 0,
  )

  const handleAlterRawData = () => {
    if (!contextWindow) return
    runChatCreateWithPrompt(true) &&
      dispatch(setAdvancedControlsSheetVisible(false))
  }

  return {
    numberOfConversations,
    largestConversationTokenCount,
    totalTokenCount,
    handleAlterRawData,
  }
}
