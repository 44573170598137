import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { setCurrentMessage, setPrompt } from "features/insights/insightsSlice"
import { useEffect } from "react"
import he from "he"
import { ChatTextAreaProps } from "features/insights/types"
import { InsightsUtilsRunners } from "features/insights/utils/llmUtils/llmUtilRunners"

const ChatTextArea: React.FC<ChatTextAreaProps> = ({ textareaRef }) => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { runChatMessage } = InsightsUtilsRunners()

  const adjustHeight = () => {
    if (textareaRef.current) {
      const newLineCount = textareaRef.current.value.split("\n").length

      if (newLineCount > 1) {
        textareaRef.current.style.height = "auto"
        textareaRef.current.style.height = `calc(${textareaRef.current.scrollHeight}px - 16px)`
      } else {
        textareaRef.current.style.height = ""
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault()
      runChatMessage()
    }
  }

  const handleInput = () => {
    adjustHeight()
  }

  useEffect(() => {
    if (textareaRef.current) {
      adjustHeight()
    }
  }, [insightsState.selectedSummaryPrompt.prompt, textareaRef.current?.value])

  return (
    <textarea
      ref={textareaRef}
      onInput={handleInput}
      onKeyDown={handleKeyDown}
      className="chat-input"
      value={he.decode(insightsState.currentChatMessage.prompt)}
      placeholder="Write a prompt"
      onChange={(e) => {
        dispatch(setCurrentMessage(he.encode(e.target.value)))
        dispatch(
          setPrompt({
            prompt: he.encode(e.target.value),
            promptType: "summary",
          }),
        )
      }}
    />
  )
}

export default ChatTextArea
