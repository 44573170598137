import logoSvg from "assets/MosaikView.svg"

const parent = {
  alignItems: "center",
  gap: "calc(12rem / 16)",
  transform: "scale(1.125)",
  transformOrigin: "left top",
  width: "calc(100% / 1.125)",
  marginBottom: ".125rem",
}

const logo = {
  height: "1.75rem",
}

const separator = {
  height: "calc(20rem / 16)",
  width: "1px",
  backgroundColor: "#dfdfdf",
  marginTop: "calc(3rem / 16)",
}

const title = {
  fontSize: "calc(19.5rem / 16)",
  paddingLeft: "calc(1rem / 16)",
  color: "#BDBDBD",
  margin: "calc(1rem / 16) 0 0 calc(-2rem / 16)",
  fontWeight: "400",
  paddingBottom: ".125rem",
}

const FeatureTitle = ({ featureName }: { featureName: string }) => {
  return (
    <div className="flex-row" style={parent}>
      <img src={logoSvg} style={logo} draggable={false} />
      <div className="separator" style={separator} />
      <h2 style={title}>{featureName}</h2>
    </div>
  )
}

export default FeatureTitle
