import Button from "@ingka/button"
import { CircularProgress } from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { InsightsUtilsRunners } from "../../utils/llmUtils/llmUtilRunners"
import { useEffect } from "react"
import { setAnimateButton } from "../../insightsSlice"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"

const InsightsButton = () => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { currentThread, inputDisabled } = useInsightsHelperFunctions()
  const { runInsights } = InsightsUtilsRunners()

  const initialMessageType =
    currentThread && currentThread.chatMessages[0]?.type

  // Handle advanced insights button animation
  useEffect(() => {
    if (insightsState.animateButton) {
      const timer = setTimeout(() => {
        dispatch(setAnimateButton(false))
      }, 500)

      return () => clearTimeout(timer)
    }
  }, [insightsState.animateButton])

  return (
    <>
      <div
        className={`${insightsState.animateButton && insightsState.advancedControlsVisible ? "animate" : ""}`}
      >
        <Button
          type="emphasised"
          disabled={inputDisabled}
          onClick={runInsights}
          small={true}
        >
          {insightsState.insightsLoading ? (
            <CircularProgress color="primary" />
          ) : initialMessageType === "data" ? (
            "Reconfigure"
          ) : (
            "Generate"
          )}
        </Button>
      </div>
    </>
  )
}

export default InsightsButton
