import { useAppDispatch } from "app/hooks"
import {
  setAdvancedControlsSheetVisible,
  setAnimateButton,
} from "features/insights/insightsSlice"
import { ToolbarProps } from "features/insights/types"
import { InsightsUtilsRunners } from "features/insights/utils/llmUtils/llmUtilRunners"

const InsertedDataToolbar = ({ disabled }: ToolbarProps) => {
  const dispatch = useAppDispatch()
  const { runChatCreateWithPrompt } = InsightsUtilsRunners()

  return (
    <div className="flex-row message-toolbar">
      <div className="flex-row message-toolbar">
        <button
          disabled={disabled}
          onClick={() => {
            dispatch(setAdvancedControlsSheetVisible(true))
            dispatch(setAnimateButton(true))
          }}
        >
          Reconfigure data
        </button>
        <button disabled={disabled} onClick={() => runChatCreateWithPrompt()}>
          Use default data (and create a new chat)
        </button>
      </div>
    </div>
  )
}

export default InsertedDataToolbar
