import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import { useTokenEstimates } from "features/insights/utils/hooks/useTokenEstimates"

const RawDataInformation = () => {
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { contextWindow } = useInsightsHelperFunctions()
  const { totalTokenCount } = useTokenEstimates()

  if (contextWindow)
    return (
      <>
        <div
          className="flex-row spaced"
          style={{ marginTop: "1.25rem", opacity: ".5" }}
        >
          <label className="skapa-label scaled no-wrap">
            ← More conversations
          </label>
          <label className="skapa-label scaled no-wrap">
            More tokens per conversation →
          </label>
        </div>
        <div
          className="flex-row"
          style={{ margin: "1.25rem 0 0 0", width: "100%" }}
        >
          <table>
            <tbody>
              <tr>
                <th>Property</th>
                <th>Size (tokens)</th>
              </tr>
              <tr>
                <td>Context window</td>
                <td>{Math.floor(contextWindow / 1000)}K</td>
              </tr>
              <tr>
                <td>Max raw data size</td>
                <td>
                  {Math.ceil(
                    (contextWindow * insightsState.insightsDataShare) /
                      100 /
                      1000,
                  )}
                  K
                </td>
              </tr>
              <tr>
                <td>Current raw data size</td>
                <td>{Math.ceil(totalTokenCount / 1000)}K</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
}

export default RawDataInformation
