import { ChangeEvent } from "react"
import ConversationsSkeleton from "../ConversationsSkeleton"
import { RootState } from "app/store"
import { useAppSelector } from "app/hooks"
import { VList } from "virtua"
import { Pagination } from "@mui/material"
import ConversationSearch from "features/conversationSearch/ConversationSearch"

interface ResultsListProps {
  totalAmountOfConversations: number
  renderConversationItems: JSX.Element[]
  handlePaginationChange: (event: ChangeEvent<unknown>, page: number) => void
}

const ResultsList: React.FC<ResultsListProps> = ({
  totalAmountOfConversations,
  renderConversationItems,
  handlePaginationChange,
}) => {
  const listConversationState = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  const skeleton = (
    <div style={{ flexGrow: 1 }}>
      <ConversationsSkeleton />
    </div>
  )

  const conversationList = (
    <VList style={{ flexGrow: 1, height: "100%" }}>
      {renderConversationItems}
    </VList>
  )

  const pagination = (
    <div className="flex-row" style={{ marginTop: ".25rem" }}>
      <ConversationSearch />
      <div style={{ flexGrow: "1", flex: "0 0 auto" }}>
        <Pagination
          onChange={handlePaginationChange}
          count={Math.ceil(totalAmountOfConversations / 200)}
          size="small"
        />
      </div>
    </div>
  )

  return (
    <>
      {totalAmountOfConversations !== 0 && pagination}
      <div style={{ overflow: "hidden" }}>
        {listConversationState.loadingConversations
          ? skeleton
          : conversationList}
      </div>
    </>
  )
}

export default ResultsList
